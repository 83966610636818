<template>
    <div class="login-container">
        <div class="form-card-img" :style="`background-image: url('${cover_img}')`" ></div>
        <div class="login-form">
            <div class="flex-column">
                <img style="width: 250px" :src="logo_img" />
                <span style="margin: 1rem;font-size: 19px">ยินดีต้อนรับสู่ Vecabo Management</span>
                <div class="flex-column" style="width: 100%; align-items: initial;">
                    <input_primary
                        style="margin: 0.5rem 0"
                        type="text"
                        icon="person"
                        placeholder="ชื่อผู้ใช้"
                        @input_model="username = $event"
                    />
                    <input_primary
                        style="margin: 0.5rem 0"
                        type="password"
                        icon="lock"
                        placeholder="รหัสผ่าน"
                        @input_model="password = $event"
                        :enter_press="sign_in"
                    />
                    <a-button type="primary" @click="sign_in()" class="primary-button" style="margin: 0.5rem 0">เข้าสู่ระบบ</a-button>
                    <router-link to="/user/forgot_password" class="nav-link">ลืมรหัสผ่าน?</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import input_primary from '../components/input_primary.vue'
import UserService from '../api/UserService.js'

export default {
  components: { input_primary },
    name: 'Login',
    data () {
        return {
            cover_img: require('@/assets/images/hiso-bus-rental-bg-homepage.jpg'),
            logo_img: require('@/assets/images/vecabo-label.png'),

            username: null,
            password: null
        }
    },
    methods: {
        async sign_in () {
            const data = await UserService.login({ username: this.username, password: this.password })
            if (data.token) {
                localStorage.setItem('token', data.token)
                localStorage.setItem('role', data.role)
                localStorage.setItem('companyData', JSON.stringify(await UserService.profile()))

                location.reload()
            }
        }
    },
    mounted() {
        if (localStorage.getItem('token')) {
            setTimeout(() => {
                this.$router.push('/app/dashboard')
            }, 100)
        }
    }
}
</script>

<style lang="scss">
    .login-container {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
    }
    .login-form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media (min-width: 992px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    .form-card-img {
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-position: 50% 50%;


        @media (min-width: 992px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }

</style>