<template>
    <div style="height: 100vh; padding: 15px">
        <div v-if="render_type == 'all'" class="app-content">
            <a-card title="ตรวจเช็ครถก่อนออกปฎิบัติงาน (Roll call)">
                <template #extra>
                    <a-space>
                        <a-button style="display: flex" type="primary" size="large" @click="show_export_rollcall=true">
                            ออกรายงาน Roll call
                        </a-button>
                        <a-col style="margin-left: 1rem;display: flex;align-items: center">
                            <a-switch v-model:checked="require_rollcall" :disabled="!editable"/>
                            <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
                        </a-col>
                        <a-select v-model:value="selected_date_filter" style="width: 150px;">
                            <a-select-option value="issue_date" key="1" selected>วันที่แจ้งตรวจสอบ</a-select-option>
                            <a-select-option value="check_end" key="2">วันที่แจ้งตรวจเสร็จสิ้น</a-select-option>
                        </a-select>
                        <a-range-picker
                            v-model:value="roll_call_range"
                            :show-time="{ format: 'HH:mm' }"
                            format="DD/MM/YYYY HH:mm"
                            :placeholder="['เริ่มต้น', 'สิ้นสุด']"
                            :minuteStep="1"
                        />
                        <router-link v-if="editable" :to="`/app/roll-call/add`">
                            <a-button style="display: flex" type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                เพิ่มรายการ Roll call
                            </a-button>
                        </router-link>
                    </a-space>
                </template>
                <a-row type="flex" style="align-items: end;">
                    <a-col flex="1 0" style="margin-right:1rem">
                        <a-input-search v-model:value="search_car" :placeholder="`ค้นหา ทะเบียนรถ`"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <label>บริษัทรถ:</label>
                        <a-select style="width: 100%" v-model:value="search_car_company" placeholder="เลือกบริษัทรถ"
                            :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ fullname, id }, i in car_company_list" :key="i" :value="fullname">
                                {{ fullname }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <label>รถ:</label>
                        <a-select style="width: 100%" v-model:value="search_car" placeholder="เลือกรถ"
                            :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ plate_no, id }, i in car_list" :key="i" :value="plate_no">
                                {{ plate_no }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <label>สถานะ:</label>
                        <a-select style="width: 100%" v-model:value="search_status" placeholder="เลือกสถานะ"
                            :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option :value="1">ทำแล้ว</a-select-option>
                            <a-select-option :value="2">ยังไม่ได้ทำ</a-select-option>
                        </a-select>
                    </a-col>
                    <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
                </a-row>
                <br>
                <a-row>
                    <a-table :columns="([
                        {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'car',
                            key: 'car',
                            width: 100,
                            align: 'center',
                        }, {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 100,
                            align: 'center',
                        }, 
                        {
                            title: 'สาเหตุการเข้ารับการตรวจ',
                            dataIndex: 'reason',
                            key: 'reason',
                            width: 225,
                            align: 'center',
                        },
                        {
                            title: 'ชื่อพนักงานขับรถ',
                            dataIndex: 'driver_name',
                            key: 'driver_name',
                            width: 200,
                            align: 'center',
                        },
                        {
                            title: 'วันที่แจ้งตรวจสอบ',
                            dataIndex: 'issue_date',
                            key: 'issue_date',
                            width: 175,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
                                multiple: 2,
                            }
                        }, 
                        // {
                        //     title: 'วันที่รับแจ้งตรวจ',
                        //     dataIndex: 'check_start',
                        //     key: 'check_start',
                        //     width: 175,
                        //     align: 'center',
                        //     sorter: {
                        //         compare: (a, b) => new Date(a.check_start) - new Date(b.check_start),
                        //         multiple: 3,
                        //     }
                        // }, 
                        {
                            title: 'วันที่แจ้งตรวจเสร็จสิ้น',
                            dataIndex: 'check_end',
                            key: 'check_end',
                            width: 175,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.check_end) - new Date(b.check_end),
                                multiple: 1,
                            }
                        }, 
                        {
                            title: 'ขื่อผู้ตรวจ',
                            dataIndex: 'inspector',
                            key: 'inspector',
                            width: 200,
                            align: 'center',
                        },
                        {
                            title: 'สถานะ',
                            dataIndex: 'status',
                            key: 'status',
                            width: 125,
                            align: 'center',
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ].filter(({ key }) => editable ? true : key != 'id'))" :pagination="false" :data-source="filtered_list(roll_call_list)" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <router-link 
                                    :to="`/app/roll-call/${record.id}`"
                                >
                                    <span style="font-size: 14px"
                                        class="material-symbols-outlined"
                                    >
                                        drive_file_rename_outline
                                    </span>
                                </router-link>
                                <a-divider type="vertical"></a-divider>
                                <a  
                                    @click="remove_rollcall(record.id)"
                                >
                                    <span style="font-size: 18px"
                                        class="material-symbols-outlined">delete
                                    </span>
                                </a>
                            </template>
                            <template v-else-if="column.key === 'accident_detail'">
                                {{ record.accident_details?.map(({ detail }) => detail).join(', ') }}
                            </template>
                            <template v-else-if="column.key === 'car_company'">
                                {{ record.car_company?.fullname }}
                            </template>
                            <template v-else-if="column.key === 'car'">
                                {{ record.car?.plate_no }}
                            </template>
                            <template v-else-if="column.key === 'status'">
                                <a-tag :color="record.pass_all != null ?'green':'yellow'">
                                    {{ record.pass_all != null ? 'ทำแล้ว' : 'ยังไม่ได้ทำ' }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key === 'check_start' || column.key == 'issue_date' || column.key == 'check_end'">
                                {{ record[column.key] ? render_date(record[column.key]) : '-' }}
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>
        <div v-else class="app-content">
            <a-card title="ตรวจเช็ครถก่อนออกปฎิบัติงาน (Roll call)">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>
                <a-form :model="create_roll_call" name="create_roll_call" autocomplete="off" layout="vertical"
                    @finish="submit_create_roll_call" @finishFailed="submit_create_roll_call_failed">
                    <a-collapse v-model:activeKey="activeKey">
                        <a-collapse-panel key="1" header="ข้อมูลที่ต้องการสำหรับตรวจเช็ครถ ">
                            <template #extra>
                                <p v-if="[
                                    create_roll_call.car_company_id,
                                    create_roll_call.car_id,
                                    create_roll_call.issue_date,
                                    // create_roll_call.check_start,
                                    create_roll_call.check_end,
                                    create_roll_call.reason,
                                    // create_roll_call.inspector
                                ].filter(e => e == null).length" style="margin: 0;color: grey">
                                    {{`(ต้องระบุอีก ${[
                                        create_roll_call.car_company_id,
                                        create_roll_call.car_id,
                                        create_roll_call.issue_date,
                                        // create_roll_call.check_start,
                                        create_roll_call.check_end,
                                        create_roll_call.reason,
                                        // create_roll_call.inspector
                                    ].filter(e => e == null).length})`}}
                                </p>
                            </template>

                            <a-row>
                                <a-col :span="6">
                                    <a-form-item label="บริษัทรถ" name="car_company_id"
                                        :rules="[{ required: true, message: 'โปรดเลือกบริษัทรถ' }]">
                                        <a-select v-model:value="create_roll_call.car_company_id"
                                            placeholder="เลือกบริษัท">
                                            <a-select-option v-for="{ id, fullname }, i in car_company_list" :key="i"
                                                :value="id">{{ fullname }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="รถที่รับการตรวจ" name="car_id"
                                        :rules="[{ required: true, message: 'โปรดเลือกรถที่รับการตรวจ' }]">
                                        <a-select v-model:value="create_roll_call.car_id" placeholder="รถที่รับการตรวจ"
                                            @change="select_car">
                                            <a-select-option v-for="{ id, plate_no } in car_list" :key="id"
                                                :value="id">{{ plate_no }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="สาเหตุที่รับการตรวจ" name="reason"
                                        :rules="[{ required: true, message: 'โปรดเลือกสาเหตุที่รับการตรวจ' }]">
                                        <a-input v-model:value="create_roll_call.reason"
                                            placeholder="โปรดระบุสาเหตุที่รับการตรวจ" />
                                    </a-form-item>
                                </a-col>
                            </a-row>

                            <a-row>
                                <a-col :span="6">
                                    <a-form-item label="วันเวลาที่แจ้งตรวจสอบ" name="issue_date"
                                        :rules="[{ required: true, message: 'โปรดเลือกวันที่แจ้งตรวจสอบ :' }]">
                                        <a-date-picker v-model:value="create_roll_call.issue_date"
                                            placeholder="วันที่แจ้งอุบัติเหตุ" :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm" :minuteStep="1" :locale="lang_th" />
                                    </a-form-item>
                                </a-col>
                                <!-- <a-col :span="6">
                                    <a-form-item label="เวลาเข้ารับการตรวจ" name="check_start"
                                        :rules="[{ required: true, message: 'โปรดเลือกเวลาเข้ารับการตรวจ :' }]">
                                        <a-date-picker v-model:value="create_roll_call.check_start"
                                            placeholder="เวลาเข้ารับการตรวจ" :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm" :minuteStep="1" :locale="lang_th" />
                                    </a-form-item>
                                </a-col> -->
                                <a-col :span="6">
                                    <a-form-item label="วันที่ตรวจเสร็จสิ้น" name="check_end"
                                        :rules="[{ required: true, message: 'โปรดเลือกวันที่ตรวจเสร็จสิ้น :' }]">
                                        <a-date-picker v-model:value="create_roll_call.check_end"
                                            placeholder="วันที่แจ้งอุบัติเหตุ" :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm" :minuteStep="1" :locale="lang_th" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item 
                                        label="ชื่อผู้ตรวจ" 
                                        name="inspector"
                                    >
                                        <a-input v-model:value="create_roll_call.inspector"
                                            placeholder="ชื่อผู้ตรวจ"
                                            />
                                    </a-form-item>
                                </a-col>
                            </a-row>

                            <a-row>
                                <a-col :span="6">
                                    <a-form-item label="ทะเบียนรถ">
                                        <a-input v-model:value="preview_selected.plate_no" placeholder="ทะเบียนรถ"
                                            disabled />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="ประเภทรภ">
                                        <a-input v-model:value="preview_selected.car_group_name" placeholder="ประเภทรภ"
                                            disabled />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="ชื่อพนักงานขับรถ">
                                        <a-input v-model:value="create_roll_call.driver_name"
                                            placeholder="ชื่อพนักงานขับรถ" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item label="พนักงานบริการประจำรถ">
                                        <!-- <a-input v-model:value="preview_selected.sub_driver_name" -->
                                        <a-input v-model:value="create_roll_call.main_car_service"
                                            placeholder="พนักงานบริการประจำรถ" />
                                    </a-form-item>
                                </a-col>
                            </a-row>

                        </a-collapse-panel>

                        <a-collapse-panel key="2" header="พนักงานขับรถ และพนักงานประจำรถ">
                            <a-table :columns="[
                                {
                                    title: 'ลำดับ',
                                    dataIndex: 'index',
                                    key: 'index',
                                    width: 50,
                                    align: 'center',
                                }, {
                                    title: 'รายละเอียด',
                                    dataIndex: 'detail',
                                    key: 'detail',
                                    width: 130
                                }, {
                                    title: 'พนักงานขับรถ',
                                    dataIndex: 'driver',
                                    key: 'driver',
                                    width: 150,
                                    align: 'start',
                                }, {
                                    title: 'พนักงานบริการประจำรถ',
                                    dataIndex: 'sub_driver',
                                    key: 'sub_driver',
                                    width: 150,
                                    align: 'center',
                                }, {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 40,
                                    align: 'center',
                                    fixed: 'right'
                                }
                            ]" :pagination="false" :data-source="driver_section" bordered size="small"
                                :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'id'">
                                        <a @click="clear_row([`driver${record.index}`, `sub_driver${record.index}`])"><span
                                                style="font-size: 18px"
                                                class="material-symbols-outlined">delete</span></a>
                                    </template>
                                    <template v-else-if="column.key === 'driver'">
                                        <a-upload v-model:file-list="record.driver.fileList" list-type="picture"
                                            class="upload-list-inline"
                                            :before-upload="beforeUpload"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, `driver${record.index}_photo`)"
                                            method="GET"
                                            @remove="remove_uploaded_file(`driver${record.index}_photo`)"
                                            :openFileDialogOnClick="openFileDialogOnClick">
                                            <a-space>
                                                <a-select v-if="record.index < 3"
                                                    v-model:value="create_roll_call[`driver${record.index}_is_pass`]"
                                                    style="width: 100px">
                                                    <a-select-option :value="0">ไม่ผ่าน</a-select-option>
                                                    <a-select-option :value="1">ผ่าน</a-select-option>
                                                </a-select>
                                                <a-input-number v-else
                                                    v-model:value="create_roll_call[`driver${record.index}_amount`]"
                                                    placeholder="กรอกตัวเลข" style="width: 100px" />
                                                <a-input-number v-if="record.index == 5"
                                                    v-model:value="create_roll_call[`driver${record.index}_bp`]"
                                                    placeholder="กรอกตัวเลข" style="width: 100px"/>
                                                <a-input
                                                    v-model:value="create_roll_call[`driver${record.index}_remark`]"
                                                    placeholder="รายละเอียดอื่นๆ" :disabled="create_roll_call[`driver${record.index}_is_pass`] == 1" />
                                                <a-button @click="openFileDialogOnClick = true" :disabled="create_roll_call[`driver${record.index}_is_pass`] == 1">
                                                    <span style="font-size: 20px"
                                                        class="material-symbols-outlined">photo_camera</span>
                                                </a-button>
                                            </a-space>
                                        </a-upload>
                                    </template>
                                    <template v-else-if="column.key === 'sub_driver'">
                                        <a-upload v-model:file-list="record.sub_driver.fileList" list-type="picture"
                                            class="upload-list-inline" 
                                            :before-upload="beforeUpload"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, `sub_driver${record.index}_photo`)"
                                            method="GET"
                                            @remove="remove_uploaded_file(`sub_driver${record.index}_photo`)"
                                            :openFileDialogOnClick="openFileDialogOnClick">
                                            <a-space v-if="record.index != 5">
                                                <a-select v-if="record.index < 3"
                                                    v-model:value="create_roll_call[`sub_driver${record.index}_is_pass`]"
                                                     style="width: 100px">
                                                    <a-select-option :value="0">ไม่ผ่าน</a-select-option>
                                                    <a-select-option :value="1">ผ่าน</a-select-option>
                                                </a-select>
                                                <a-input-number v-else
                                                    v-model:value="create_roll_call[`sub_driver${record.index}_amount`]"
                                                    placeholder="กรอกตัวเลข" style="width: 100px" :disabled="record.sub_driver.is_pass == 1" />
                                                <a-input
                                                    v-model:value="create_roll_call[`sub_driver${record.index}_remark`]"
                                                    placeholder="รายละเอียดอื่นๆ" :disabled="record.sub_driver.is_pass == 1" />
                                                <a-button @click="openFileDialogOnClick = true" :disabled="record.sub_driver.is_pass == 1">
                                                    <span style="font-size: 20px"
                                                        class="material-symbols-outlined">photo_camera</span>
                                                </a-button>
                                            </a-space>
                                        </a-upload>
                                    </template>
                                </template>
                            </a-table>
                        </a-collapse-panel>

                        <a-collapse-panel key="3" header="Cleanliness & Health & Safety">
                            <a-table :columns="[
                                {
                                    title: 'ลำดับ',
                                    dataIndex: 'index',
                                    key: 'index',
                                    width: 25,
                                    align: 'center',
                                }, {
                                    title: 'Cleanliness',
                                    dataIndex: 'clean',
                                    key: 'clean',
                                    width: 250,
                                    align: 'center',
                                }, {
                                    title: 'Health',
                                    dataIndex: 'health',
                                    key: 'health',
                                    width: 250,
                                    align: 'center',
                                }, {
                                    title: 'Safety',
                                    dataIndex: 'safe',
                                    key: 'safe',
                                    width: 250,
                                    align: 'center',
                                }, {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 40,
                                    align: 'center',
                                    fixed: 'right'
                                }
                            ]" :pagination="false" :data-source="cleanliness_section" bordered size="small"
                                :scroll="{ x: 1750 }">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'id'">
                                        <a @click="clear_row([`clean${record.index}`, `health${record.index}`, `safe${record.index}`])"><span style="font-size: 18px"
                                                class="material-symbols-outlined">delete</span></a>
                                    </template>
                                    <template v-else-if="column.key == 'index'">
                                        {{ record.index }}
                                    </template>
                                    <template v-else>
                                        <div style="display: flex; justify-content: space-between; align-items: center">
                                            <span>{{ record[column.key].title }}</span>
                                            <a-upload v-model:file-list="record[column.key].fileList"
                                                list-type="picture" class="upload-list-inline"
                                                :before-upload="beforeUpload"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, `${column.key}${record.index}_photo`)"
                                                method="GET"
                                                @remove="remove_uploaded_file(`${column.key}${record.index}_photo`)"
                                                :openFileDialogOnClick="openFileDialogOnClick">
                                                <a-space>
                                                    <a-select v-model:value="create_roll_call[`${column.key}${record.index}_is_pass`]"
                                                        style="width: 100px">
                                                        <a-select-option :value="0">ไม่ผ่าน</a-select-option>
                                                        <a-select-option :value="1">ผ่าน</a-select-option>
                                                    </a-select>
                                                    <a-input v-model:value="create_roll_call[`${column.key}${record.index}_remark`]"
                                                        placeholder="รายละเอียดอื่นๆ" :disabled="create_roll_call[`${column.key}${record.index}_is_pass`] == 1" />
                                                    <a-button @click="openFileDialogOnClick = true" :disabled="create_roll_call[`${column.key}${record.index}_is_pass`] == 1">
                                                        <span style="font-size: 20px"
                                                            class="material-symbols-outlined">photo_camera</span>
                                                    </a-button>
                                                </a-space>
                                            </a-upload>
                                        </div>
                                    </template>
                                </template>
                            </a-table>
                        </a-collapse-panel>

                        <a-collapse-panel key="4" header="Bus Inspection">
                            <a-table :columns="[
                                {
                                    title: 'จุด',
                                    dataIndex: 'located',
                                    key: 'located',
                                    width: 25,
                                    align: 'center',
                                }, {
                                    title: 'ลำดับ',
                                    dataIndex: 'index',
                                    key: 'index',
                                    width: 20,
                                    align: 'center',
                                }, {
                                    title: 'รายการ',
                                    dataIndex: 'list',
                                    key: 'list',
                                    width: 80,
                                    align: 'start',
                                }, {
                                    title: 'รายละเอียด',
                                    dataIndex: 'details',
                                    key: 'details',
                                    width: 200,
                                    align: 'center',
                                }, {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 30,
                                    align: 'center',
                                    fixed: 'right'
                                }
                            ]" :pagination="false" :data-source="bus_inspection" bordered size="small"
                                :scroll="{ x: 1000 }">
                                <template #bodyCell="{ column, record,  }">
                                    <template v-if="column.key === 'id'">
                                        <a-row v-for="index, i in record.details.length" justify="center">
                                            <a @click="clear_row([`bus${record.start_at + i}`])"><span style="font-size: 18px"
                                                    class="material-symbols-outlined">delete</span></a>
                                        </a-row>
                                    </template>
                                    <template v-else-if="column.key == 'index'">
                                        <a-row v-for="index in record.index" justify="center">
                                            {{ index }}
                                        </a-row>
                                    </template>
                                    <template v-else-if="column.key == 'located'">
                                        {{ record[column.key] }}
                                    </template>
                                    <template v-else-if="column.key == 'list'">
                                        <a-row v-for="content in record[column.key]" justify="start">
                                            {{ content }}
                                        </a-row>
                                    </template>
                                    <template v-else-if="column.key == 'details'">
                                        <a-row v-for="content, i in record[column.key]" justify="center">
                                            <a-upload v-model:file-list="record.details[i].fileList"
                                                list-type="picture" class="upload-list-inline"
                                                :before-upload="beforeUpload"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, `bus${record.start_at + i}_photo`)" method="GET"
                                                @remove="remove_uploaded_file(`bus${record.start_at + i}_photo`)"
                                                :openFileDialogOnClick="openFileDialogOnClick">
                                                <a-space>
                                                    <a-select v-model:value="create_roll_call[`bus${(record.start_at + i)}_is_pass`]"
                                                        style="width: 100px">
                                                        <a-select-option :value="0">ไม่ผ่าน</a-select-option>
                                                        <a-select-option :value="1">ผ่าน</a-select-option>
                                                    </a-select>
                                                    <a-input v-model:value="create_roll_call[`bus${(record.start_at + i)}_remark`]"
                                                        placeholder="รายละเอียดอื่นๆ" style="width: 400px" :disabled="create_roll_call[`bus${(record.start_at + i)}_is_pass`] == 1" />
                                                    <a-button @click="openFileDialogOnClick = true" :disabled="create_roll_call[`bus${(record.start_at + i)}_is_pass`] == 1">
                                                        <span style="font-size: 20px"
                                                            class="material-symbols-outlined">photo_camera</span>
                                                    </a-button>
                                                </a-space>
                                            </a-upload>
                                        </a-row>
                                    </template>
                                </template>
                            </a-table>
                        </a-collapse-panel>

                        <a-collapse-panel key="5" header="บันทึกรายการข้อบกพร่องและการแก้ไข">
                            <a-card title="รายการข้อบกพร่องและการแก้ไข">
                                <template #extra>
                                    <a-space>
                                        <a-button style="display: flex" type="primary" size="large" @click="show_create_damage = true">
                                            <template #icon><span
                                                    class="material-symbols-outlined">Add</span></template>
                                            เพิ่มรายการ Roll call
                                        </a-button>
                                    </a-space>
                                </template>
                                <a-table :columns="[
                                    {
                                        title: 'ลำดับ',
                                        dataIndex: 'index',
                                        key: 'index',
                                        width: 30,
                                        align: 'center',
                                    }, {
                                        title: 'รายการ',
                                        dataIndex: 'detail',
                                        key: 'detail',
                                        width: 120,
                                        align: 'start',
                                    }, {
                                        title: 'สถานะ',
                                        dataIndex: 'is_fixed',
                                        key: 'is_fixed',
                                        width: 60,
                                        align: 'center',
                                    }, {
                                        title: 'ผู้รับผิดชอบ',
                                        dataIndex: 'assignee',
                                        key: 'assignee',
                                        width: 120,
                                        align: 'center',
                                    }, {
                                        title: 'หมายเหตุ',
                                        dataIndex: 'remark',
                                        key: 'remark',
                                        width: 120,
                                        align: 'center',
                                    }, {
                                        title: 'ตัวเลือก',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: 40,
                                        align: 'center',
                                        fixed: 'right'
                                    }
                                ]" :pagination="false" :data-source="create_roll_call.damages" bordered size="small"
                                    :scroll="{ x: 1200 }">
                                    <template #bodyCell="{ column, record, index }">
                                        <template v-if="column.key === 'id'">
                                            <a @click="remove_damage(record)"><span style="font-size: 18px"
                                                    class="material-symbols-outlined">delete</span></a>
                                        </template>
                                        <template v-else-if="column.key == 'index'">
                                            {{ index + 1 }}
                                        </template>
                                        <template v-else-if="column.key == 'assignee'">
                                            <a-input v-model:value="record.assignee" placeholder="ผู้รับผิดชอบ"
                                                style="width: 200px" />
                                        </template>
                                        <template v-else-if="column.key == 'is_fixed'">
                                            <a-select v-model:value="record.is_fixed" style="width: 100px">
                                                <a-select-option :value="0">รอแก้ไข</a-select-option>
                                                <a-select-option :value="1">แก้ไขแล้ว</a-select-option>
                                            </a-select>
                                        </template>
                                        <template v-else-if="column.key == 'remark'">
                                            <a-upload list-type="picture" class="upload-list-inline"
                                                v-model:file-list="record.fileList" 
                                                :before-upload="beforeUpload"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, record.photo)" method="GET"
                                                @remove="remove_uploaded_file(record.photo)"
                                                :openFileDialogOnClick="openFileDialogOnClick">
                                                <a-space>
                                                    <a-input v-model:value="record.remark"
                                                        placeholder="รายละเอียดอื่นๆ" />
                                                    <a-button @click="openFileDialogOnClick = true">
                                                        <span style="font-size: 20px"
                                                            class="material-symbols-outlined">photo_camera</span>
                                                    </a-button>
                                                </a-space>
                                            </a-upload>
                                        </template>
                                    </template>
                                </a-table>
                            </a-card>
                        </a-collapse-panel>

                    </a-collapse>

                    <a-row justify="end">
                        <a-button type="primary" size="large" html-type="submit">
                            <template #icon><span class="material-symbols-outlined">save</span></template>
                            บันทึก
                        </a-button>
                    </a-row>
                </a-form>
            </a-card>
        </div>

        <a-modal 
            v-model:visible="show_create_damage" 
            title="เพิ่มรายการอุบัติเหตุ" 
            ok-text="บันทึก" cancel-text="ปิด" 
            @ok="save_create_damage">
            <a-form
                :model="create_damage"
                name="create_damage" autocomplete="off" layout="vertical"
            >
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="รายการข้อบกพร่องและการแก้ไข" :rules="[{ required: true, message: 'ระบุรายการข้อบกพร่องและการแก้ไข' }]">
                            <a-input v-model:value="create_damage.detail" placeholder="ระบุรายการข้อบกพร่องและการแก้ไข" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="สถานะ" :rules="[{ required: true, message: 'ระบุสถานะ' }]">
                            <a-select v-model:value="create_damage.is_fixed">
                                <a-select-option :value="0">รอแก้ไข</a-select-option>
                                <a-select-option :value="1">แก้ไขแล้ว</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ผู้รับผิดชอบ" :rules="[{ required: true, message: 'ระบุผู้รับผิดชอบ' }]">
                            <a-input v-model:value="create_damage.assignee" placeholder="ระบุผู้รับผิดชอบ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="หมายเหตุ" :rules="[{ required: true, message: 'ระบุหมายเหตุ' }]">
                            <a-input v-model:value="create_damage.remark" placeholder="ระบุหมายเหตุ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="รูปภาพ">
                            <a-upload list-type="picture" class="upload-list-inline"
                                v-model:file-list="create_damage.fileList" 
                                :before-upload="beforeUpload"
                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                @change="upload_files($event, create_damage.photo)" method="GET"
                                @remove="remove_uploaded_file(create_damage.photo)"
                                :openFileDialogOnClick="openFileDialogOnClick">
                                <a-space>
                                    <a-button @click="openFileDialogOnClick = true" style="width: 100%">
                                        <span style="font-size: 20px"
                                            class="material-symbols-outlined">photo_camera</span>
                                    </a-button>
                                </a-space>
                            </a-upload>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
    </div>

    <a-modal v-model:visible="show_export_rollcall" title="รายงาน Roll call" :footer="null">
        <template #footer>
            <a-button type="primary" @click="">บันทึก</a-button>
        </template>
    </a-modal>
    <a-modal v-model:visible="show_export_rollcall" title="รายงาน Roll call" :footer="null">
        <a-form :model="export_excel" layout="vertical" @finish="exportFile()">
          <a-row justify="center">
            <a-col :span="20">
              <a-form-item label="วันที่เริ่ม" :rules="[{ required: true, message: 'โปรดระบุวันที่เริ่ม' }]" :name="time_start">
                  <a-date-picker style="width: 300px;" v-model:value="export_excel.time_start" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="center">
            <a-col :span="20">
              <a-form-item label="วันที่สิ้นสุด" :rules="[{ required: true, message: 'โปรดระบุวันที่สิ้นสุด' }]" name="time_end">
                  <a-date-picker style="width: 300px;" v-model:value="export_excel.time_end" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="end">
              <a-button type="primary" html-type="submit">บันทึก</a-button>
          </a-row>
        </a-form>
    </a-modal>
</template>

<script>
import Utility from '@/utility'
import { message } from 'ant-design-vue';
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import SystemService from '../../api/SystemService';
import RollCallService from '../../api/RollCallService';
import UserService from "../../api/UserService";
import * as xlsx from "xlsx-js-style";

export default {
    name: 'RollCall',
    computed: {
        render_type() {
            this.clear_create_roll_call()
            if (this.$route.params.type != 'add' && this.$route.params.type != 'all') {
                this.init_edit_mode(this.$route.params.type)
                return this.$route.params.type
            } else {
                return this.$route.params.type
            }
        }
    },
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('roll_call'),
            activeKey: ['1'],
            lang_th: lang_th,
            openFileDialogOnClick: false,

            require_rollcall: false,
            //Default List
            roll_call_range: null,
            search_car: null,
            search_car_company: null,
            search_plate_no: null,
            search_status: null,

            //List
            car_list: [],
            car_company_list: [],
            roll_call_list: [],

            //Create
            preview_selected: {
                plate_no: null,
                driver_name: null,
                sub_driver_name: null,
                car_group_name: null,
            },
            create_roll_call: {
                car_company_id: null,
                car_id: null,
                issue_date: null,
                check_start: null,
                check_end: null,
                damages: [],
                driver1_is_pass: 1,
                driver2_is_pass: 1,
                sub_driver1_is_pass: 1,
                sub_driver2_is_pass: 1,
                clean1_is_pass: 1,
                clean2_is_pass: 1,
                clean3_is_pass: 1,
                health1_is_pass: 1,
                health2_is_pass: 1,
                health3_is_pass: 1,
                safe1_is_pass: 1,
                safe2_is_pass: 1,
                safe3_is_pass: 1,
                bus1_is_pass: 1,
                bus2_is_pass: 1,
                bus3_is_pass: 1,
                bus4_is_pass: 1,
                bus5_is_pass: 1,
                bus6_is_pass: 1,
                bus7_is_pass: 1,
                bus8_is_pass: 1,
                bus9_is_pass: 1,
                bus10_is_pass: 1,
                bus11_is_pass: 1,
                bus12_is_pass: 1,
                bus13_is_pass: 1,
                bus14_is_pass: 1,
                bus15_is_pass: 1,
                bus16_is_pass: 1,
                //Add default to 1
                inspector: null, 
                driver_name: null,
                main_car_service: null,
            },
            create_damage: {
                detail: null,
                is_fixed: null,
                assignee: null,
                remark: null,
                photo: null,
            },
            show_create_damage: false,

            //Edit
            driver_section: [
                {
                    index: 1,
                    detail: 'การแต่งกาย / ความพร้อมปฎฺิบัติงาน /ใบขับขี่',
                    driver: {
                        fileList: [],
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    sub_driver: {
                        fileList: [],
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 2,
                    detail: 'หน้ากากอนามัย',
                    driver: {
                        fileList: [],
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    sub_driver: {
                        fileList: [],
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 3,
                    detail: 'ปริมาณแอลกอฮอล์',
                    driver: {
                        fileList: [],
                        amount: null,
                        remark: null,
                        photo: null,
                    },
                    sub_driver: {
                        fileList: [],
                        amount: null,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 4,
                    detail: 'อุณหภูมิร่างกาย',
                    driver: {
                        fileList: [],
                        amount: null,
                        remark: null,
                        photo: null,
                    },
                    sub_driver: {
                        fileList: [],
                        amount: null,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 5,
                    detail: 'ความดันโลหิต',
                    driver: {
                        fileList: [],
                        amount: null,
                        bp: null,
                        remark: null,
                        photo: null,
                    },
                    sub_driver: {
                        fileList: [],
                        amount: null,
                        remark: null,
                        photo: null,
                    }
                },
            ],
            cleanliness_section: [
                {
                    index: 1,
                    clean: {
                        fileList: [],
                        title: 'ห้องคนขับ',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    health: {
                        fileList: [],
                        title: 'พ่นฆ่าเชื้อ/อบโอโซน',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    safe: {
                        fileList: [],
                        title: 'เข็มขัดนิรภัย',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 2,
                    clean: {
                        fileList: [],
                        title: 'ห้องโดยสาร',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    health: {
                        fileList: [],
                        title: 'เจลแอลกอฮอล์',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    safe: {
                        fileList: [],
                        title: 'ถังดับเพลิง/ค้อนทุบ',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    }
                }, {
                    index: 3,
                    clean: {
                        fileList: [],
                        title: 'ห้องน้ำ',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    health: {
                        fileList: [],
                        title: 'ถาดพรมน้ำยาฆ่าเชื้อ',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    },
                    safe: {
                        fileList: [],
                        title: 'ประตูฉุกเฉิน/หลังคา',
                        is_pass: 1,
                        remark: null,
                        photo: null,
                    }
                }
            ],
            bus_inspection: [
                {
                    located: 'หน้า',
                    index: ['1.', '2.', '3.'],
                    list: ['ตัวถัง / กระจกหน้า / กระจกมองข้าง', 'ระบบไฟหน้ารถ', 'ป้ายหน้ารถ'],
                    start_at: 1,
                    details: [
                        {
                            fileList: [],
                            is_pass_1: 1,
                            remark_1: null,
                            photo_1: null,
                        },
                        {
                            fileList: [],
                            is_pass_2: 1,
                            remark_2: null,
                            photo_2: null,
                        },
                        {
                            fileList: [],
                            is_pass_3: 1,
                            remark_3: null,
                            photo_3: null,
                        }
                    ]
                }, {
                    located: 'ขวา',
                    index: ['4.', '5.'],
                    list: ['ตัวถัง / กระจกด้านขวา', 'ล้อยาง / ลมยางขวา'],
                    start_at: 4,
                    details: [
                        {
                            fileList: [],
                            is_pass_4: 1,
                            remark_4: null,
                            photo_4: null,
                        },
                        {
                            fileList: [],
                            is_pass_5: 1,
                            remark_5: null,
                            photo_5: null,
                        }
                    ]
                }, {
                    located: 'หลัง',
                    index: ['6.', '7.'],
                    list: ['ระบบไฟท้าย', 'เสียงผิดปกติเครื่องยนต์'],
                    start_at: 6,
                    details: [
                        {
                            fileList: [],
                            is_pass_6: 1,
                            remark_6: null,
                            photo_6: null,
                        },
                        {
                            fileList: [],
                            is_pass_7: 1,
                            remark_7: null,
                            photo_7: null,
                        }
                    ]
                }, {
                    located: 'ซ้าย',
                    index: ['8.', '9.', '10.'],
                    list: ['ตัวถัง / กระจกด้านซ้าย', 'ล้อยาง  ลมยางซ้าย', 'เก๊ะสัมภาระ / ประตูผู้โดยสาร'],
                    start_at: 8,
                    details: [
                        {
                            fileList: [],
                            is_pass_8: 1,
                            remark_8: null,
                            photo_8: null,
                        },
                        {
                            fileList: [],
                            is_pass_9: 1,
                            remark_9: null,
                            photo_9: null,
                        },
                        {
                            fileList: [],
                            is_pass_10: 1,
                            remark_10: null,
                            photo_10: null,
                        }
                    ]
                }, {
                    located: 'ห้องคนขับ',
                    index: ['11.', '12.', '13.'],
                    list: ['คอนโซลหน้า / หน้าปัดรถ', 'เครื่องรูดบัตร / อุปกร์ GPS', 'วิทยุสื่อสาร'],
                    start_at: 11,
                    details: [
                        {
                            fileList: [],
                            is_pass_11: 1,
                            remark_11: null,
                            photo_11: null,
                        },
                        {
                            fileList: [],
                            is_pass_12: 1,
                            remark_12: null,
                            photo_12: null,
                        },
                        {
                            fileList: [],
                            is_pass_13: 1,
                            remark_13: null,
                            photo_13: null,
                        }
                    ]
                }, {
                    located: 'ภายใน',
                    index: ['14.', '15.', '16.'],
                    list: ['เครื่องเสียง / ทีวี / VTR', 'ระบบแสงสว่างภายใน', 'สภาพที่นั่ง และสิ่งอำนวยความสะดวก'],
                    start_at: 14,
                    details: [
                        {
                            fileList: [],
                            is_pass_14: 1,
                            remark_14: null,
                            photo_14: null,
                        },
                        {
                            fileList: [],
                            is_pass_15: 1,
                            remark_15: null,
                            photo_15: null,
                        },
                        {
                            fileList: [],
                            is_pass_16: 1,
                            remark_16: null,
                            photo_16: null,
                        }
                    ]
                }
            ],
            show_export_rollcall: false,
            selected_date_filter: "issue_date",
            export_excel: {
                time_start: null,
                time_end: null,
            },
        }
    },
    methods: {
        async exportFile() {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
            const time_start = dayjs(this.export_excel.time_start).format('YYYY-MM-DD');
            const time_end = dayjs(this.export_excel.time_end).format('YYYY-MM-DD');
            let result = await RollCallService.getRollcallByTimeStartAndTimeEnd(time_start, time_end);
            result = result.data;
            console.log("result", result);
            const headersColumn = [
                    { header: "วันที่", key: "issue_date" }, 
                    { header: "เวลาตรวจ", key: "issue_date_time" }, 
                    { header: "รถที่รับตรวจ", key: "car_check" }, 
                    { header: "ชื่อพนักงานขับรถ", key: "driver_name" }, 
                    { header: "ชื่อพนักงานบริการรถ", key: "main_car_service" }, 
                    { header: "การแต่งกาย / ความพร้อมปฎิบัติงาน / ใบขับขี่", key: "driver1_is_pass" }, 
                    { header: "หน้ากากอนามัย", key: "driver2_is_pass" }, 
                    { header: "ปริมาณแอลกอฮอล์", key: "driver3_amount" },
                    { header: "อุณหภูมิร่างกาย", key: "driver4_amount"}, 
                    { header: "ความดันโลหิต", key: "driver5_amount" }, 
                    { header: "ห้องคบขับ", key: "clean1_is_pass" }, 
                    { header: "ห้องโดยสาร", key: "clean2_is_pass" },
                    { header: "ห้องน้ำ", key: "clean3_is_pass" },
                    { header: "พ่นฆ่าเชื้อ/อบโอโซน", key: "health1_is_pass" },
                    { header: "เจลแอลกอฮอล์", key: "health2_is_pass" },
                    { header: "ถาดพรมน้ำฆ่าเชื้อ", key: "health3_is_pass" },
                    { header: "เข็ฒขัดนิรภัย", key: "safe1_is_pass" }, 
                    { header: "ถังดับเพลิง/ค้อนทุบ", key: "safe2_is_pass" }, 
                    { header: "ประตูฉุกเฉิน/หลังคา", key: "safe3_is_pass" }, 
                //     { header: "Bus Inspection", key: "col20" }, 
                    { header: "รายการข้อบกพร่องและการแก้ไข", key: "damages" }, 
                    { header: "ชื่อผู้ตรวจ", key: "inspector" }, 
            ];

            const workBook = xlsx.utils.book_new();
            const workSheet = xlsx.utils.json_to_sheet(result, { header: headersColumn.map(h => h.key) });
            const lastRow = result.length + 1; // +1 for headers

            // Add headers manually because json_to_sheet doesn't automatically add headers
            xlsx.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });
                    
            // Style the headers
            headersColumn.forEach((header, index) => {
                let cell = workSheet[xlsx.utils.encode_cell({ c: index, r: 0 })];
                cell.s = {
                font: { bold: true },
                fill: { fgColor: { rgb: "BCD6AC" } },
                border: { bottom: { style: "thin" } , right: { style: "thin" } , left: { style: "thin" } , top: { style: "thin" } },
                alignment: { horizontal: "center" }
                };
            });

            // Style the data rows
            for (let row = 1; row < lastRow; row++) {
                for (let col = 0; col < headersColumn.length; col++) {
                    const cellAddress = xlsx.utils.encode_cell({ c: col, r: row });
                    let cell = workSheet[cellAddress];
                    if (!cell) {
                        cell = { v: "" };
                        workSheet[cellAddress] = cell;
                    }
                    cell.s = {
                        border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                        alignment: { horizontal: "center" }
                    };
                }
            }

            // Adjust the column width as needed
            workSheet['!cols'] = headersColumn.map(() => ({ wch: 32 }));

            xlsx.utils.book_append_sheet(workBook, workSheet, "Report");
            xlsx.writeFile(workBook, "รายงาน Rollcall.xlsx");
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
            console.log("export_excel", this.export_excel);
        },
        remove_rollcall(id) {
            message.loading({ content: 'กำลังลบ...', key: 'updatable'})
            SystemService.delete_all('RollCall', id)
                .then(() => {
                    message.success({ content: 'ลบสำเร็จ', key: 'updatable', duration: 2 })
                    this.init_options()
                })
                .catch(err => {
                    message.error({ content: 'ลบไม่สำเร็จ', key: 'updatable', duration: 2 })
                })
        },
        render_date(date) {
            return dayjs(date).format('DD/MM/YYYY HH:mm')
        },
        clear_search() {
            this.search_car = null
            this.search_car_company = null
        },
        async clear_create_roll_call() {
            this.create_roll_call = {
                car_company_id: null,
                car_id: null,
                issue_date: null,
                check_start: null,
                check_end: null,
                damages: [],
                driver_name: null,
            }
        },
        remove_damage(e) {
            message.loading({ content: 'กำลังลบ...', key: 'updatable'})
            const { id } = e
            if (id) {
                SystemService.delete_all('Damage', id)
                    .then(() => {
                        message.success({ content: 'ลบสำเร็จ', key: 'updatable', duration: 2 })
                        this.create_roll_call.damages.splice(e, 1)
                    })
            } else {
                message.success({ content: 'ลบสำเร็จ', key: 'updatable', duration: 2 })
                this.create_roll_call.damages.splice(e, 1)
            }
        },
        save_create_damage() {
            this.create_roll_call.damages.push(this.create_damage)
            this.create_damage = {
                detail: null,
                is_fixed: null,
                assignee: null,
                remark: null,
                photo: null,
            }
        },
        submit_create_roll_call() {
            message.loading({ content: 'กำลังบันทึก...', key: 'updatable'})
            const result = this.create_roll_call
            // result.issue_date = result.issue_date.format('DD/MM/YYYY HH:mm:ss')
            // result.check_start = result.check_start.format('DD/MM/YYYY HH:mm:ss')
            // result.check_end = result.check_end.format('DD/MM/YYYY HH:mm:ss')
            result.company_id = JSON.parse(localStorage.getItem('companyData')).id

            if (result.id) {
                SystemService.update_all('RollCall', { data: result }, result.id)
                    .then(() => {
                        message.success({ content: 'บันทึกสำเร็จ', key: 'updatable', duration: 2 })
                        this.$router.push('/app/roll-call/all')
                        this.clear_create_roll_call()
                        this.init_options()
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error('บันทึกไม่สำเร็จ')
                    })
            } else {
                RollCallService.createRollcall(result)
                    .then(() => {
                        message.success({ content: 'บันทึกสำเร็จ', key: 'updatable', duration: 2 })
                        this.$router.push('/app/roll-call/all')
                        this.clear_create_roll_call()
                        this.init_options()
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error('บันทึกไม่สำเร็จ')
                    })
            }
        },
        submit_create_roll_call_failed() {
            message.error('กรุณากรอกข้อมูลให้ครบถ้วน')
        },
        filtered_list(array) {
            console.log("seleced_date_filter", this.selected_date_filter);
            return array.filter((e) => {
                 if(this.search_status){
                    return this.search_status == 1 ? e.pass_all !=null : e.pass_all == null
                }else if (this.search_car_company) {
                    return e.car_company.fullname.includes(this.search_car_company)
                } else if (this.search_car) {
                    return e.car.plate_no.includes(this.search_car)
                }else if (this.roll_call_range) {
                    if (this.roll_call_range.length > 0) {
                        const time_start = dayjs(this.roll_call_range[0]).format('x')
                        const time_end = dayjs(this.roll_call_range[1]).format('x')
                        if(this.selected_date_filter == "issue_date"){
                            return dayjs(e.issue_date).format('x') >= time_start && dayjs(e.issue_date).format('x') <= time_end
                        } else if(this.selected_date_filter == "check_end"){
                            return dayjs(e.check_end).format('x') >= time_start && dayjs(e.check_end).format('x') <= time_end
                        }
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            })
        },
        async upload_files(e, target) {
            this.getBase64(e.file.originFileObj, target)
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        async remove_uploaded_file(key) {
            this.create_roll_call[key] = ''
        },
        async getBase64(file, target) {
            try {
                const vue = this.create_roll_call
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue[target] = reader.result
                };
            } catch (err) {
                console.log(err);
            }
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('Image must smaller than 1MB!');
            }
            return isLt2M;
        },
        select_car(value) {
            const { plate_no, driver, car_group } = this.car_list.find((car) => car.id === value)
            console.log(plate_no, driver, car_group);
            this.preview_selected.plate_no = plate_no
            // this.preview_selected.driver_name = driver ? `${driver.firstname} ${driver.lastname}` : ''
            this.preview_selected.car_group_name = car_group ? car_group.name : ''
        },
        clear_row(target_list) {
            target_list.forEach((target) => {
                for (var key in this.create_roll_call) {
                    if (key.includes(target)) {
                        this.create_roll_call[key] = null
                    }
                }
            })
        },
        async init_options() {
            await SystemService.get_all('CarCompany').then((res) => {
                this.car_company_list = res
            })

            await SystemService.get_all('Car').then((res) => {
                this.car_list = res
            })

            await SystemService.get_all('RollCall').then((res) => {
                this.roll_call_list = res.sort((a,b) => new Date(b.issue_date) - new Date(a.issue_date))
            })
            this.require_rollcall = JSON.parse(localStorage.getItem('companyData')).require_rollcall == 1
        },
        async init_edit_mode(id) {
            this.create_roll_call = await SystemService.get_id('RollCall', id)
            const { check_start, check_end, issue_date } = this.create_roll_call
            this.create_roll_call.check_start = check_start? dayjs(check_start) : null
            this.create_roll_call.check_end = check_end? dayjs(check_end) : null
            this.create_roll_call.issue_date = issue_date? dayjs(issue_date) : null
            for (var key in this.create_roll_call) {
                if (key.includes('photo') && this.create_roll_call[key]) {
                    this.photo_parser(key, this.create_roll_call[key])
                }

                if (key == 'damages') {
                    this.create_roll_call[key].forEach((damage, index) => {
                        damage.fileList = []
                        damage.fileList.push({ 
                            uid: `photo_${index+1}`,
                            name: '',
                            status: 'done',
                            url: damage.photo
                        })
                    })
                }
            }
        },
        photo_parser(key, value) {
            //Get number from string
            const index = key.match(/\d+/)[0]
            //Replace number with empty string
            const target = key.replace(/\d+_photo/, '')
            
            const rules = {
                key: target,
                index: index,
            }

            const defaultKey = {
                bus: 'bus_inspection',
                sub_driver: 'driver_section',
                driver: 'driver_section',
                clean: 'cleanliness_section',
                health: 'cleanliness_section',
                safe: 'cleanliness_section'
            }

            const location = this[defaultKey[rules.key]].find((e) => {
                if (typeof e.index == 'number') {
                    return e.index == rules.index
                } else {
                    return e.index.map(i => i.replace('.','')).includes(rules.index)
                }
            })
            
            if (location) {
                if (location[rules.key]) {
                    location[rules.key].fileList.push({ 
                        uid: `photo_${rules.index+1}`,
                        name: '',
                        status: 'done',
                        url: value
                    })
                } else {
                    const e = location.index.map(i => i.replace('.','')).indexOf(rules.index)
                    location.details[e].fileList.push({ 
                        uid: `photo_${rules.index+1}`,
                        name: '',
                        status: 'done',
                        url: value
                    })
                }
            }

        },
        async init_mode() {
            if (this.render_type != 'add' && this.render_type != 'all') {
                
            } else {
                this.booking_files = []
                await this.init_options()
            }
        },
    },
    watch: {
        openFileDialogOnClick: function (val) {
            if (val) {
                setTimeout(() => {
                    this.openFileDialogOnClick = false
                }, 100)
            }
        },
        require_rollcall(newVal) {
            if (newVal != null) {
                const { company_id } = JSON.parse(localStorage.getItem('user_profile'))
                SystemService.update_all('Company', { data: { require_rollcall: newVal } }, company_id)
                    .then(res => {
                        const original = JSON.parse(localStorage.getItem('companyData'))
                        original.require_rollcall = newVal
                        localStorage.setItem('companyData', JSON.stringify(original))
                    })
            }
        },
        user_profile_username : function(newVal) {
            this.create_roll_call.inspector = newVal;
        }
    },
    async mounted() {
        await this.init_mode()
        await this.init_options()
    },
}
</script>
<style scoped>

.ant-upload-list-picture::-webkit-scrollbar {
  opacity: 1;
}
.ant-row {
    margin: 5px 0
}

.ant-col-12,
.ant-col-24,
.ant-col-4,
.ant-col-8,
.ant-col-6 {
    padding: 0 4px
}

.ant-select,
.ant-picker {
    width: 100%
}

.ant-col {
    width: 100%
}

.ant-form-item {
    margin-bottom: 0;
}

.quotation-container {
    padding: 15px;
    height: 100vh;
}

.blue-card {
    background-color: #1890ff;
    color: white;
    border-radius: 5px;
    margin: 5px
}

.red-card {
    background-color: #a2a2a2;
    color: white;
    border-radius: 5px;
    margin: 5px
}
</style>