import httpClient from './httpClient';
import { io } from 'socket.io-client';

class SocketService {
  constructor() {
    this.socket = null;
    this.baseURL = httpClient({ requiresAuth: true }).defaults.baseURL
  }

  connect(room) {
    if (!this.socket) {
      this.socket = io(this.baseURL);
      this.socket.emit('join_room', room);
    }
  }

  onBroadcast(message) {
    if (this.socket) {
      this.socket.on('broadcast', message);
    }
  }
}

const socketService = new SocketService();
export default socketService;
