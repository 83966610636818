<template>
    <div>
        <a-modal v-model:visible="show_export_modal" :title="form.topic_name? form.topic_name:'ออกใบเสนอราคา'" :afterClose="afterClose" width="1000px"
            :footer="null">
            <a-form :model="form" layout="vertical" @finish="export_select">
                <a-row justify="end">
                    <a-col>
                        <a-form-item :label="`ภาษาของ${form.topic_name? form.topic_name:'ใบเสนอราคา / Quotation'}`">
                            <a-radio-group v-model:value="form.lang">
                                <a-radio value="th">ภาษาไทย</a-radio>
                                <a-radio value="en">ภาษาอังกฤษ</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">บริษัทรถ</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อบริษัทรถ" name="car_company_id"
                            :rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]">
                            <a-select v-model:value="form.car_company_id" @change="auto_fill_car_company">
                                <a-select-option v-for="{ id, fullname } in car_company_list" :value="id" :key="id">{{
                                        fullname
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ที่อยู่">
                            <p v-if="form.car_company">{{ form.car_company.address }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชน">
                            <p v-if="form.car_company">{{ form.car_company.tax_id }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="12">
                        <a-form-item label="เบอร์โทรศัพท์">
                            <p v-if="form.car_company">{{ form.car_company.phone_no }}</p>
                        </a-form-item>

                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="เบอร์แฟกซ์">
                            <p v-if="form.car_company">{{ form.car_company.fax_no }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-divider orientation="left">ลูกค้า</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อบริษัทลูกค้า" name="client_id"
                            :rules="[{ required: true, message: 'โปรดเลือกลูกค้า' }]">
                            <a-select v-model:value="form.client_id" @change="auto_fill_client">
                                <a-select-option v-for="{ id, fullname } in client_list" :value="id" :key="id">{{
                                        fullname
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ที่อยู่">
                            <p v-if="form.client">{{ form.client.address }}</p>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชน" >
                            <p v-if="form.client">{{ form.client.tax_id }}</p>
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="เบอร์โทรศัพท์">
                            <p v-if="form.client">{{ form.client.phone_no }}</p>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">ผู้จองรถ</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อผู้จองรถ">
                            <a-select v-model:value="form.client_contact_id" @change="auto_fill_client_contact">
                                <a-select-option v-for="{ id, firstname, lastname } in getClientContactList(client_contact_list)" :value="id"
                                    :key="id">
                                    {{ `${firstname} ${lastname? lastname:'-'}` }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ผู้ประสานงานหน้างาน">
                            <p v-if="form.temporary_coordinator">{{ form.temporary_coordinator }}</p>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เบอร์โทรศัพท์ผู้จองรถ" >
                            <p v-if="form.client_contact">{{ form.client_contact?.phone_no }}</p>
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="เบอร์โทรศัพท์ผู้ประสานงานหน้างาน" >
                            <p v-if="form.client_contact">{{ form.temporary_coordinator_phone_no }}</p>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">รายละเอียดการ{{ form.topic_name? form.topic_name:'ออกใบเสนอราคา' }}</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="หัวข้อเอกสาร" >
                            <a-input v-model:value="form.topic_name" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col>
                        <a-checkbox v-model:checked="form.show_flight">แสดงรายละเอียดของการเดินทางโดนสังเขป</a-checkbox>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ช่องใส่ข้อความอิสระ" >
                            <a-textarea v-model:value="form.remark" placeholder="ใส่ข้อความอิสระ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
                <a-row>
                    <a-col v-if="form.pay_type == 3">
                        <a-form-item label="เปอร์เซ็นต์จ่าย">
                            <a-input type="number" v-model:value="price_percent" :min="1" :max="100"
                                @change="cal_price(form.pay_type)" disabled />
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="จำนวนเงิน">
                            <a-input type="number" v-model:value="form.total_price"
                                disabled />
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="ประเภทการจ่ายเงิน">
                            <a-select v-model:value="form.pay_type" @change="cal_price" disabled>
                                <a-select-option :value="1">ราคาเต็ม</a-select-option>
                                <a-select-option :value="2">จำนวน</a-select-option>
                                <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="รายละเอียดชำระเงิน">
                            <a-input v-model:value="form.pay_extend" disabled> </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="ส่วนลด">
                            <a-input :value="form.discount" disabled />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">ข้อมูลภาษี</a-divider>
                <a-row>
                    <a-col>
                        <a-checkbox disabled v-model:checked="form.inc_vat" >มีการคิดภาษีมูลค่าเพิ่ม</a-checkbox>
                        <a-checkbox disabled v-model:checked="form.withholding" >มีการหักภาษี ณ ที่จ่าย {{ withholding_type(form.car_company, 'text') }}</a-checkbox>
                    </a-col>
                </a-row>
                
                <a-divider orientation="left">หมายเหตุ</a-divider>
                <!-- Remark -->
                <a-row>
                    <a-col v-for="(item, index) in remark_types_all" :key="index">
                        <remark_group_new 
                            @remove_remark = "remove_remark"
                            @move_up = "move_up"
                            @move_down = "move_down"
                            :remark_type = "item"
                            :remark_detail = "filter_remark(item.id)"
                        />
                    </a-col>
                    <a-col>
                        <a-form-item label="เลือกหมายเหตุ" >
                            <a-select v-model:value="remark_selected" >
                                <a-select-option 
                                    v-for="(item2 , index) in remark_select" 
                                    :value="item2.remark" 
                                    :key="item2.id" 
                                    @click="add_remark(item2.id)"
                                >
                                    {{ item2.remark }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <!-- <a-row>
                    <a-col :span="24">
                        <a-form-item label="ระบุหมายเหตุเพิ่มเติม" name="remark_more">
                            <a-input
                            placeholder="ระบุหมายเหตุเพิ่มเติม"
                            v-model:value="create_booking.remark_more"
                            ></a-input>
                        </a-form-item>
                    </a-col>
                </a-row> -->
                <!-- Remark -->

                <a-row>
                    <a-col>
                        <a-form-item label="หมายเหตุเพิ่มเติม">
                            <a-textarea v-model:value="form.remark_more" placeholder="หมายเหตุเพิ่มเติม" :rows="4"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row justify="space-between">
                    <a-col>
                        <a-form-item>
                            <a-checkbox v-model:checked="is_th" >รูปแบบวันที่ภาษาไทย</a-checkbox>                            
                            <a-checkbox v-model:checked="show_qt_date" >แสดงวันที่เอกสาร</a-checkbox>                            
                            <a-checkbox v-model:checked="show_issue_date" >แสดงวันที่ออก</a-checkbox>
                            <a-checkbox v-model:checked="show_due_date" >แสดงวันที่ใช้ได้ถึง</a-checkbox>
                            <a-checkbox v-model:checked="show_refno" >แสดงเลขที่เอกสาร</a-checkbox>
                            <a-checkbox v-model:checked="show_stamp" >แสดงตราประทับ</a-checkbox>
                            <a-checkbox v-model:checked="show_sale" >แสดงลายเซ็น</a-checkbox>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="วันที่ออก" :rules="[{ required: true, message: 'โปรดระบุวันที่ออก' }]" >
                            <a-date-picker v-model:value="form.created_at" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                :minuteStep="1"
                                :locale="lang_th"
                                :disabled="!show_issue_date"
                                />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="ใช้ได้ถึง" :rules="[{ required: true, message: 'โปรดระบุใช้ได้ถึง' }]">
                            <a-date-picker v-model:value="form.take_order_at" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                :minuteStep="1"
                                :locale="lang_th"
                                :disabled="!show_due_date"
                                />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="เอกสารเลขที่" :rules="[{ required: true, message: 'โปรดระบุเอกสารเลขที่' }]">
                            <a-input v-model:value="form.booking_no"  :disabled="!show_refno" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7" style="margin-top: 1rem;">
                        <a-form-item label="ผู้เสนอราคา" :rules="[{ message: 'โปรดเลือกผู้เสนอราคา' }]">
                            <a-select 
                                v-model:value="selected_sale_id"
                                @change="onSaleChanged"
                                :disabled="!show_sale"
                            >
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id" :key="id">
                                    {{ `${firstname} ${lastname}` }}
                                </a-select-option>
                            </a-select>
                            <a-image v-if="form.sale?.signature" :src="form.sale.signature" style="margin-top: 1rem; width: 400px;" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row justify="end">
                    <a-button type="primary" html-type="submit">ตกลง</a-button>
                </a-row>
            </a-form>
        </a-modal>

        <div v-if="show_pdf" id="content" style="height: 1754px;width: 1240px;display: flex;flex-direction: column;justify-content: space-between;padding:1em 4em">
            <div style="height: 95%">
                <div id="header" style="display:flex;justify-content: space-between;align-items:end;border-bottom: 2px solid black;">
                    <div>
                        <span style="font-size: 18px;">
                            <img crossorigin="anonymous" style="height: 90px" :src="form.car_company.logoSc" />
                            <img crossorigin="anonymous" style="height: 90px;width: 180px;object-fit: cover;" :src="form.car_company.logoIso" />
                            <h1>{{ form.car_company.fullname }}</h1>
                            <h2>{{ form.car_company.address }}</h2>
                            <h2>เลขประจำตัวผู้เสียภาษี : {{ form.car_company.tax_id }}</h2>
                            <h2>โทรศัพท์ : {{ form.car_company.phone_no }} อีเมล : {{ form.car_company.email }} Line ID: {{ form.car_company.line_id }}</h2>
                        </span>
                    </div>
                    <img crossorigin="anonymous" style="height: 170px" :src="form.car_company.logo" />
                </div>
                <br>
                <h1 style="text-align: center;">{{ form.topic_name? form.topic_name:'ใบเสนอราคา / Quotation' }}</h1>
                <div
                    style="display: flex;flex-direction: column;justify-content: space-between;border-left: 1px solid black;border-right: 1px solid black;">
                    <table class="doc-table" style="border-bottom: none;border-left: none;border-right: none">
                        <tr>
                            <td style="width: 7%;white-space: nowrap;">เรียน: {{ form.client.fullname }}</td>
                            <td style="width: 28%;"></td>
                            <td style="width: 35%"></td>
                            <td :colspan="3" style="width: 30%;border-left: 1px solid black;">
                                <span v-if="show_refno">ใบเสนอราคาที่: {{ form.booking_no }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">เลขประจำตัวผู้เสียภาษี: {{ form.client.tax_id }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                วันที่:<span v-if="show_qt_date"> {{ time_now }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">ที่อยู่: {{ form.client.address }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                <span v-if="show_issue_date">วันที่ออก: {{ render_date(form.created_at) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">ชื่อผู้จองรถ: {{ form.client_contact? form.client_contact.firstname:'-' }}
                                {{ form.client_contact? form.client_contact.lastname:'-' }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                <span v-if="show_due_date">ใช้ได้ถึง: {{ render_date(form.take_order_at) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">เบอร์โทรผู้จองรถ: {{ form.client_contact?.phone_no }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">ผู้เสนอราคา: 
                                {{ (form.sale? form.sale.firstname:user_profile.username) }}
                                        {{ (form.sale? form.sale.lastname:user_profile.username) }} 
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">อีเมลผู้จองรถ: {{ form.client_contact?.email }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">ผู้ประสานงานหน้างาน: {{ form.temporary_coordinator }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">เบอร์โทรผู้เสนอราคา:
                                {{ form.sale?.phone_no }}</td>
                        </tr>
                        <tr>
                            <td :colspan="3">เบอร์โทรผู้ประสานงานหน้างาน: {{ form.temporary_coordinator_phone_no }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;"></td>
                        </tr>
                        
                        <tr style="text-align:center">
                        <td style="width: 2rem;border-top:1px solid black;border-bottom: 1px solid black; font-weight: bold;">รายการ
                            </td>
                            <td :colspan="2"
                                style="border-top:1px solid black; font-weight: bold; border-bottom: 1px solid black;text-align:start">
                                รายละเอียดงาน</td>
                            <td style="border-top:1px solid black; font-weight: bold; border-bottom: 1px solid black">หน่วย</td>
                            <td style="border-top:1px solid black; font-weight: bold; border-bottom: 1px solid black">ราคา/หน่วย(บาท)</td>
                            <td style="border-top:1px solid black; font-weight: bold; border-bottom: 1px solid black;text-align: end;">
                                รวมเป็นเงิน(บาท)</td>

                            
                        </tr>
                        <tr v-if="form.show_flight">
                            <td></td>
                            <td :colspan="2">วันที่เริ่มสัญญา : {{ render_time(form.booking_detail.time_start,'DD-MM-YYYY HH:mm')}} วันที่สิ้นสุดสัญญา : {{ render_time(form.booking_detail.time_end,'DD-MM-YYYY HH:mm')}} ({{ form.booking_detail.total_days }} วัน)<br>
                                จุดรับ: {{ form.booking_detail?.start_location_name }}<br>
                                จุดส่ง: {{ form.booking_detail?.end_location_name }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr style="text-align:center;border-top: 2px dashed #c2c2c2;" v-for="{ time_start, time_end, car_type, car_group, quantity, cost, start_point, end_point, remark }, i in this.form.booking_detail.booking_car_types" :key="i">
                            <td >{{ (i + 1) }}</td>
                            <td :colspan="2" style="text-align:start;">
                                <span v-if="remark">
                                    <span v-for="line in remark.split('\n')">
                                        {{ line }}<br>
                                    </span>
                                </span>
                            </td>
                            <td>{{ quantity }}</td>
                            <td>{{ (Number((cost/quantity).toFixed(0))).toLocaleString() + '.00' }}</td>
                            <td style="text-align: end">{{ Number(cost).toLocaleString() + '.00' }}</td>
                        </tr>
                        <tr style="text-align:center;border-top: 2px dashed #c2c2c2;" v-for="{ price, extra: { name }, amount }, i in this.form.booking_detail.extras" :key="i">
                            <td >{{ (this.form.booking_detail.booking_car_types.length + i + 1) }}</td>
                            <td :colspan="2" style="text-align:start;word-break: break-all;">
                                {{ name }}
                            </td>
                            <td>{{ amount }}</td>
                            <td>{{ Number(price.toFixed(0)).toLocaleString() + '.00' }}</td>
                            <td style="text-align: end">{{ Number((price * amount).toFixed(0)).toLocaleString() + '.00' }}</td>
                        </tr>
                    </table>
                </div>
                <table class="doc-table">
                    <!-- footer -->
                    <tr>
                        <td style="font-weight: bold;border-top:1px solid black">ชื่อธนาคาร</td>
                        <td style="border-top:1px solid black;text-align: end;">{{ bank_name }}</td>
                        <td style="border-top:1px solid black;border-left: 1px solid black;">รวมเป็นเงิน :</td>
                        <td :colspan="4" style="width: 30%;text-align:end;border-top:1px solid black">{{ total_price.toLocaleString() + '.00' }}</td>
                        <!-- <td :colspan="4" style="width: 30%;text-align:end;border-top:1px solid black">{{ (form.total_price + (form.discount? form.discount:0)).toLocaleString() }}</td> -->
                    </tr>
                    <tr>
                        <td style="font-weight: bold">สาขา</td>
                        <td style="text-align: end">{{ form.car_company.bank_branch }}</td>
                        <td style="border-left: 1px solid black">ส่วนลด :</td>
                        <td :colspan="4" style="text-align:end">{{ form.discount? discount.toLocaleString() + '.00' : '0.00' }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">ชื่อบัญชี</td>
                        <td style="text-align: end">{{ form.car_company.bank_account_name }}</td>

                        <td v-if="!form.withholding && form.inc_vat" style="border-left: 1px solid black">ภาษีมูลค่าเพิ่ม 7% :</td>
                        <td v-else style="border-left: 1px solid black"></td>
                        <td v-if="!form.withholding && form.inc_vat" :colspan="4" style="text-align:end">
                            {{ total_vat_seven_percent.toLocaleString() + '.00' }}
                        </td>

                    </tr>
                    <tr>
                        <td style="font-weight: bold">เลขที่บัญชี</td>
                        <td style="text-align: end;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ formattedAccountNumber() }}</td>
                        <td style="font-weight: bold;border-left: 1px solid black">รวมเป็นเงินสุทธิ :</td>
                        <td :colspan="4" style="font-weight: bold;text-align: end;">{{ total_price_final.toLocaleString() + '.00' }}</td>
                        <!-- <td :colspan="4" style="font-weight: bold;text-align: end;">{{ (form.total_price + Number(form.inc_vat? ((form.total_price + form.discount - sum_extras) * 0.07):0)).toLocaleString() }}</td> -->
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td :colspan="4" style="font-weight: bold;border-left: 1px solid black">({{ price_to_word(total_price_final.toLocaleString())}})
                        </td>
                    </tr>
                    <tr v-if="form.withholding">
                        <td :colspan="7" style="border-top: 1px solid black;text-align: end">สามารถหักภาษี ณ ที่จ่าย {{withholding_type(form.car_company, 'text')}}
                            (ค่าขนส่ง) : {{ total_vat_one_percent.toLocaleString() + '.00' }} บาท</td>
                    </tr>
                    <tr>
                        <td :colspan="7" style="border-top: 1px solid black;text-align: start;font-size: 16px;">
                            <div style="text-overflow: ellipsis; overflow: hidden;height: 15%;">
                                <h4>เงื่อนไขการขนส่ง</h4>
                                <div v-for="(remark_type, i) in remark_types_all" :key="i">
                                <strong><span><u>{{ remark_type.remark_name }}</u></span></strong><br>
                                    <span v-for="(remark, j) in filter_remark(remark_type.id)" :key="j">
                                        {{ `${j + 1}. ${remark.remark}` }} <br>
                                    </span>
                                </div>
                            </div>
                            <div style="text-overflow: ellipsis; overflow: hidden;height: 15%; margin-top: 10px;">
                                <strong><span><u>หมายเหตุเพิ่มเติม</u></span></strong><br>
                                <pre style="background-color: white; border-color: white;"><span>{{ form.remark_more ? form.remark_more : '-' }}</span></pre>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="form.pay_extend && form.pay_extend != ''">
                        <td :colspan="7" style="border-top: 1px solid black;text-align: start;font-size: 16px;">
                            <span>เงื่อนไขการชำระเงิน</span><br>
                            <span>{{ ['ราคาเต็ม','จำนวน','เปอร์เซ็นต์'][form.pay_type-1] }} {{ form.pay_type == 3? `(${price_percent} %)`:'' }}</span><br>
                            <span>รายละเอียดการชำระเงิน</span><br>
                            <span>{{ form.pay_extend }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan="7" style="border-top:1px solid black;border-left: 1px solid black;">
                            <div style="display: flex;justify-content: space-between;text-align: center;line-height: 2; align-items: center;">
                                <div style="margin-top: 6rem;">
                                    <div v-if="form.sale?.signature" style="height: 60px;"></div>
                                    <p>ลงชื่อ.................................................................................</p>
                                    <p>(...................................................................................)</p>
                                    <p>ผู้มีอำนาจลงนาม</p>
                                    <p>{{ form.client.fullname }}</p>
                                </div>
                                <div style="margin-top: 5rem;width: 105px;height: 105px;border: 1px dotted black;display: flex;align-items: center;justify-content: center;" v-if="show_stamp">
                                    <p>ประทับตรา </p>
                                </div>
                                <div style="margin-top: 3rem;">
                                    <img v-if="form.sale?.signature" crossorigin="anonymous" style="width: 400px; height: 80px;" :src="form.sale?.signature+'?not-from-cache-please'" />
                                    <p style="padding-top: 0.5rem;">ลงชื่อ.................................................................................</p>
                                    <!-- <p>( {{ (form.sale.firstname) }}
                                        {{ (form.sale.lastname) }} )</p> -->
                                        <p>{{ display_fullname(form.sale?.firstname,form.sale?.lastname) }}</p>

                                    <p>ผู้เสนอราคา</p>
                                    <p>{{ form.car_company.fullname }}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>


            <div style="display: flex;justify-content: space-between;">
                <span>{{ car_company_list[0].fullname_en }}</span>
                <span v-if=" car_company_list[0].company_id == 26">F-CS-0002 ฉบับแก้ไขครั้งที่ 2 วันที่อนุมัติใช้ 1 มิถุนายน 2560</span>
                <span v-else></span>
            </div>
            <!-- </div> -->
        </div>
        <div v-if="show_pdf" id="content2" style="height: 1754px;width: 1240px;display: flex;flex-direction: column;justify-content: space-between;padding:1em 4em">
            <div style="height: 95%">
            <div id="header2" style="display:flex;justify-content: space-between;align-items:end;border-bottom: 2px solid black;">
                    <div>
                        <span style="font-size: 18px;">
                            <img crossorigin="anonymous" style="height: 90px" :src="form.car_company.logoSc" />
                            <img crossorigin="anonymous" style="height: 90px;width: 180px;object-fit: cover;" :src="form.car_company.logoIso" />
                            <h1>{{ form.car_company.fullname }}</h1>
                            <h2>{{ form.car_company.address }}</h2>
                            <h2>เลขประจำตัวผู้เสียภาษี : {{ form.car_company.tax_id }}</h2>
                            <h2>โทรศัพท์ : {{ form.car_company.phone_no }} อีเมล : {{ form.car_company.email }} Line ID: {{ form.car_company.line_id }}</h2>
                        </span>
                    </div>
                    <img crossorigin="anonymous" style="height: 170px" :src="form.car_company.logo" />
                </div>
            <img v-show="car_company_list[0].company_id == 26" crossorigin="anonymous" style="margin-top: 15px; width: 940px; height: 350px; object-fit: cover; filter: contrast(120%);" src="@/assets/payment_and_qr_code.png" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import CarCompanyService from '../../api/CarCompanyService';
import SystemService from '../../api/SystemService';
import ReportService from '../../api/ReportService';
import BankService from '../../api/BankService';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import { ArabicNumberToText } from '../helpers.js'
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import { message } from 'ant-design-vue';
import Swal from 'sweetalert2';
import remark_group_new from '../remark_group_new.vue'
import BookingService from "../../api/BookingService.js";
import 'jspdf-autotable';

export default {
    props: {
        show_export_modal: Boolean,
        booking_detail: Object,
        user_name: String,
    },
    name: 'Export-Modal',
    components: {
        remark_group_new
    },
    setup() {
        const remark_component = ref(null);
        const remark_types = ref('');
        const remark_data = ref('');
        const fetchChildData = () => {
            if (remark_component.value) {
                remark_types.value = remark_component.value.remark_types;
                remark_data.value = remark_component.value.remark_list_by_type;
                let find_index = remark_data.value.map((item, index) => (item.length === 0 ? index : -1)).filter(index => index !== -1);
                let id_type = []
                find_index.forEach((index) => {id_type.push(remark_types.value[index].id)})
                remark_types.value = remark_types.value.filter(item => !id_type.includes(item.id));
                remark_data.value = remark_data.value.filter(subArray => subArray.length > 0);
            }
        };

        return {
            remark_component,
            remark_types,
            remark_data,
            fetchChildData
        }
    },
    data() {
        return {
            show_qt_date: true,
            show_issue_date: true,
            show_due_date: true,
            show_refno: true,
            show_pdf: false,
            show_stamp: true,
            show_sale: true,
            logo_url: require('@/assets/logo.jpg'),
            price_percent: 100,
            car_company_list: [],
            client_list: [],
            client_contact_list: [],
            sale_list: [],
            lang_th: lang_th,
            companyData: localStorage.getItem('companyData') ? JSON.parse(localStorage.getItem('companyData')) : {},
            user_profile: localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {},
            selected_sale_id: null,
            form: {
                lang: 'th',
                topic_name: 'ใบเสนอราคา / Quotation',
                car_company_id: null,
                car_company: null,
                client_id: null,
                client: null,
                client_contact_id: null,
                client_contact: {
                    phone_no: null,
                },
                show_flight: false,
                options: ['reducted', 'no_tax'],
                total_price: null,
                pay_type: 1,
                discount: 0,
                pay_extend: null,
                sale: null,
                temporary_coordinator : null,
                temporary_coordinator_phone_no : null,
                list_id_remark: null,
                extras: [],
            },

            is_th: true,
            qr_payment: require('@/assets/qr_payment.jpg'),
            paymentImg: require('@/assets/payment.png'),
            bank_name: '',

            remark_types_all: [],
            remark_types: {},
            remark_detail_all: [],
            remark_detail: [],
            remark_selected: "",
            remark_select: [],

            total_price: 0, // รวมเป็นเงิน
            total_price_final: 0, // รวมเป็นเงินสุทธิ
            total_pay_more: 0, // ค่าใช้จ่ายต่างๆ
            discount: 0, // ส่วนลด
            total_vat_one_percent: 0, // ภาษีมูลค่าเพิ่ม 1%
            total_vat_seven_percent: 0, // ภาษีมูลค่าเพิ่ม 7%
        }
    },
    computed: {
        sum_extras() {
            return this.form.booking_detail.extras.map(({ price, amount }) => amount * price).reduce((a, b) => a + b, 0)
        },
        time_now() {
            return this.is_th? dayjs().locale('th').format('DD MMMM BBBB'):dayjs().format('DD/MM/YYYY HH:mm')
        }
    },
    methods: {
        async init_remark(){
            this.remark_detail_all = await BookingService.get_remarks();
            this.remark_types_all = await SystemService.get_all('BookingRemarkType')
            this.remark_types_all = this.remark_types_all.filter((item) => item.flag_check == 'active')
            if(this.render_type == "add"){ //เพิ่มข้อมูลใหม่
                this.remark_detail = this.remark_detail_all.filter((item) => item.is_default == 1 && item.remark_type != null)
                this.remark_select = this.remark_detail_all.filter((item) => item.is_default == 0 && item.remark_type != null)
            }else{ //แก้ไขข้อมูล
                if (this.form.list_id_remark) { //มีข้อมูลเดิม
                this.remark_detail = this.form.list_id_remark;
                this.remark_detail_all = this.remark_detail_all.filter((item) => item.remark_type != null)

                let select = []
                this.remark_detail_all.forEach((e) => {
                    const check_if = this.remark_detail.find((item) => item.id == e.id)
                    //เพิ่มข้อมูลใน remark_select ที่ไม่มีใน remark_detail
                    if(check_if == undefined){
                    select.push(e)
                    }
                });
                this.remark_select = select
                } else { //ไม่มีข้อมูลเดิม
                this.remark_detail = []
                this.remark_select = this.remark_detail_all.filter((item) =>  item.remark_type != null)
                }
            }
        },
        filter_remark(remark_type_id){
                const result = this.remark_detail.filter((item) => item.remark_type == remark_type_id)
                return result
        },  
        add_remark(id){
            // console.log("remark_detail>>>", this.remark_detail);
            // console.log("remark_select>>>", this.remark_select);
            this.remark_detail.push(this.remark_select.find((item) => item.id == id))
            this.remark_select = this.remark_select.filter((item) => item.id != id)
        },
        remove_remark(id){
            this.remark_select.push(this.remark_detail.find((item) => item.id == id))
            this.remark_detail = this.remark_detail.filter((item) => item.id != id)
        },
        move_up(id){
            const index = this.remark_detail.findIndex((item) => item.id == id)
            if(index > 0){
                const temp = this.remark_detail[index]
                this.remark_detail[index] = this.remark_detail[index - 1]
                this.remark_detail[index - 1] = temp
            }
        },
        move_down(id){
            const index = this.remark_detail.findIndex((item) => item.id == id)
            if(index < this.remark_detail.length - 1){
                const temp = this.remark_detail[index]
                this.remark_detail[index] = this.remark_detail[index + 1]
                this.remark_detail[index + 1] = temp
            }
        },
    
        calAllTotalPrice(){
            const isVatSevenPercent = this.form.inc_vat
            const idVatOnePercent = this.form.withholding
            const total_extras = this.form.extras.map(({ price, amount }) => price * amount).reduce((a, b) => a + b, 0) // ค่าใช้จ่ายต่างๆ
            const total_car_cost = this.form.price_for_withholding ?? 0 // ราคารถ

            this.discount = this.form.discount == NaN || this.form.discount == null || this.form.discount == undefined ? 0 : Number(this.form.discount)
            this.total_price = total_car_cost

            if (isVatSevenPercent == true && idVatOnePercent == false){ // vat 7%
                this.total_price_final = Number((((this.total_price - this.discount) + total_extras) + ((this.total_price - this.discount) * 0.07)).toFixed(0))
                this.total_vat_seven_percent =  Number(((this.total_price - this.discount) * 0.07).toFixed(0))
                if(this.total_price_final == NaN || this.total_price_final == null || this.total_price_final == undefined){
                    this.total_price_final = 0
                }
            } else if(isVatSevenPercent == false && idVatOnePercent == true){ // vat 1%
                this.total_price_final =  Number((((this.total_price - this.discount) + total_extras)).toFixed(0))
                this.total_vat_one_percent =  Number(((this.total_price - this.discount) * 0.01).toFixed(0))
                if(this.total_price_final == NaN || this.total_price_final == null || this.total_price_final == undefined){
                    this.total_price_final = 0
                }
            } else { // no vat
                this.total_price_final =  Number(((this.total_price - this.discount) + total_extras).toFixed(0))
                if(this.total_price_final == NaN || this.total_price_final == null || this.total_price_final == undefined){
                    this.total_price_final = 0
                }
            }


            // all log
            // console.log('total_car_cost', total_car_cost)
            // console.log('total_extras', total_extras)
            // console.log('total_price รวมเป็นเงิน', this.total_price)
            // console.log('discount ส่วนลด', this.discount)
            // console.log('total_price_final รวมเป็นเงินสุทธิ', this.total_price_final)
            // console.log('total_vat_one_percent 1%', this.total_vat_one_percent)
            // console.log('total_vat_seven_percent 7%', this.total_vat_seven_percent)
        },
        total_price_with_vat() {
            const total = Number(this.booking_detail.total_price)
            const discount = Number(this.form.discount) ? Number(this.form.discount) : 0
            const sum = total - discount + Number(this.calVat())
            return sum
        },
        calVat() {
            const total = Number(this.booking_detail.total_price)
            const discount = this.form.discount ? Number(this.form.discount) : 0
            let vat = null
            if(this.form.inc_vat == true && this.form.withholding == false){ // ถ้ามีการคิดภาษีมูลค่าเพิ่ม 7%
                vat = 7/100
                const sum = (total - discount) * vat
                return sum
            } else if(this.form.inc_vat == false && this.form.withholding == true) { // ถ้ามีการหักภาษี ณ ที่จ่าย 1%
                vat = 1/100
                const sum = (total - discount) * vat
                return sum
            }else{
                return 0
            }
        },
        bankName() {
            BankService.getBankById(this.form.car_company.bank_id)
            .then(data => {
                this.bank_name = data.data.name
            })
            .catch(err => console.log(err))
           
        },
        formattedAccountNumber() {  

            if (this.form.car_company.bank_account_no.length == 10) {
            const accountNumber = this.form.car_company.bank_account_no;

            return accountNumber.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/g, '$1-$2-$3-$4');

            } else if (this.form.car_company.bank_account_no.length == 12){
             return accountNumber.replace(/(\d{1})(\d{10})(\d{1})/g, '$1-$2-$3');
            }
            
            else {
            return this.form.car_company.bank_account_no; 
            }
        },
        remarkFormatted(remark) {
            return remark.replace(/\n/g, '<br>');
        },
        getClientContactList(list){
            return list.filter(({ client_id }) => client_id == this.form.client_id)
        },
        display_fullname(firstname, lastname) {
            const firstNamePart = firstname ? firstname : '';
            const lastNamePart = lastname ? lastname : '';
            var fullName = `${firstNamePart} ${lastNamePart}`.trim()
            if (fullName !== null && fullName.trim() !== "") {
            return `(${fullName})`
            } else {
            return '(.........................................................................................)'
            }
        },
        cal_price(e)     {
            const total_extras = this.form.extras.map(({ price, amount }) => price * amount).reduce((a, b) => a + b, 0) // ค่าใช้จ่ายต่างๆ
            const total_car_cost = this.form.price_for_withholding ?? 0 // ราคารถ
            this.form.total_price = total_car_cost + total_extras
            this.booking_detail.total_price = total_car_cost + total_extras
            if (e == 1) {
                this.form.total_price = this.booking_detail.total_price ? this.booking_detail.total_price : 0
            } else if (e == 3) {
                this.form.total_price =  this.booking_detail.total_price ? Number((this.booking_detail.total_price * this.price_percent / 100).toFixed(2)) : 0
            }
            
        },
        render_date(datetime) {
            return this.is_th? dayjs(datetime).locale('th').format('DD MMMM BBBB'):dayjs(datetime).format('DD/MM/YYYY HH:mm')
        },
        price_to_word(price) {
            return ArabicNumberToText(price ? price : 0)
        },
        render_time(datetime, format) {
            return this.is_th? dayjs().locale('th').format('DD MMMM BBBB') : dayjs(datetime).format(format)
        },
        export_select() {
            this.save_settings()
            this.form.booking_detail = this.booking_detail

            

            Swal.fire({
                title: 'เลือกการส่งออก',
                icon: 'question',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'PDF',
                denyButtonText: 'DOCX',
                confirmButtonColor: '#3085d6',
                denyButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.export_pdf()
                } else if (result.isDenied) {
                    this.export_docx()
                }
            })
        },
        async export_docx() {
            try {
                this.$message.loading({
                    content: 'กำลังสร้างไฟล์...',
                    key: 'export_docx',
                    duration: 0
                });
                const { base64 } = await ReportService.export_qt({
                    form: this.form,
                    companyData: this.companyData,
                    show_options: {
                        show_qt_date: this.show_qt_date,
                        show_issue_date: this.show_issue_date,
                        show_due_date: this.show_due_date,
                        show_refno: this.show_refno,
                    },
                })
                
                const buffer = Buffer.from(base64, 'base64')
                saveAs(new Blob([buffer]), `QUOTATION-${this.form.booking_no}.docx`)
                this.$message.success({
                    content: 'สร้างไฟล์เรียบร้อย',
                    key: 'export_docx',
                    duration: 1.5
                });
                this.$emit('close')
            } catch (err) {
                console.log(err);
                this.$message.warning({
                    content: 'สร้างไฟล์ล้มเหลว',
                    key: 'export_docx',
                    duration: 1.5
                });
                this.$emit('close')
            }
        },
        // export_pdf() {
        //     this.fetchChildData();
        //     this.calAllTotalPrice();
        //     message.loading({
        //         content: 'กำลังสร้าง PDF...',
        //         key: 'export_pdf',
        //         duration: 0
        //     });
        //     this.show_pdf = true

        //     setTimeout(() => {
        //         html2canvas(document.getElementById('content'), {
        //             allowTaint: true,
        //             useCORS: true,
        //             dpi: 72,
        //             scale: 2
        //         }).then(async(canvas) => {
        //             ///preparing image
        //             var imgData = canvas.toDataURL('image/png');
        //             var doc = new jsPDF('p', 'pt', 'a4', true);
        //             var width = doc.internal.pageSize.getWidth();
        //             var height = doc.internal.pageSize.getHeight();

        //             //# Page 1
        //             doc.addImage(imgData, 'PNG', 4, 4, width - 8, height - 8);
                    
        //             await html2canvas(document.getElementById('content2'), {
        //                 allowTaint: true,
        //                 useCORS: true,
        //                 dpi: 72,
        //                 scale: 1
        //             })
        //             .then((canvas) => {
        //                 const imagData2 = canvas.toDataURL('image/png');
        //                 //# Page 2
        //                 doc.addPage();
        //                 doc.addImage(imagData2, 'PNG', 4, 4, width - 8, height - 8);
        //             });

        //             doc.save(`Quotation-${this.booking_detail.booking_no}.pdf`);
        //             console.log(this.show_stamp);
        //             message.success({
        //                 content: 'สร้าง PDF เรียบร้อย',
        //                 key: 'export_pdf',
        //                 duration: 2
        //             });
        //         });
        //     }, 100)
        // },
        afterClose() {
            this.$emit('afterClose', true)
        },
        auto_fill_car_company() {
            const { address, tax_id, phone_no, fax_no, email, fullname, logo,logoIso,logoSc,image_payment,
                bank, bank_id, bank_branch, bank_account_no, bank_account_name, line_id, withholding_id } = this.car_company_list.find(x => x.id == this.form.car_company_id);
            this.form.car_company = {
                address, tax_id, phone_no, fax_no, email, fullname, logo, bank,logoIso,logoSc,image_payment,
                bank_id, bank_branch, bank_account_no, bank_account_name, line_id, withholding_id
            }
        },
        auto_fill_client_contact() {
            const { id, firstname, lastname, phone_no } = this.client_contact_list.find(x => x.id == this.form.client_contact_id);
            this.form.client_contact = {
                firstname, lastname, phone_no
            }
            SystemService.update_all('Booking', { data: { client_contact_id: id } }, this.booking_detail.id)
        },
        auto_fill_client() {
            const { address, tax_id, phone_no, fullname, fax_no } = this.client_list.find(x => x.id == this.form.client_id);
            this.form.client = {
                address, tax_id, phone_no, fullname, fax_no
            }
        },
        async init_options() {
            this.$message.loading({
                content: 'กำลังโหลดข้อมูล...',
                key: 'init_options',
                duration: 0
            });
            this.form = {
                lang: 'th',
                topic_name: 'ใบเสนอราคา / Quotation',
                car_company_id: null,
                car_company: null,
                client_id: null,
                client: null,
                client_contact_id: null,
                client_contact: {

                },
                show_flight: false,
                options: ['reducted', 'no_tax'],
                total_price: null,
                pay_type: 1,
                discount: 0,
                sale: null,
                extras: [],
            }

            this.car_company_list = await CarCompanyService.get_car_companies();
            this.client_list = await SystemService.get_all('Client')
            this.client_contact_list = await SystemService.get_all('ClientContact')
            this.sale_list = await SystemService.get_all('Sale')
            
            this.form.car_company_id = this.car_company_list[0].id
            this.form.car_company = this.car_company_list[0]
            // this.form.total_price = this.booking_detail.total_price ? (this.booking_detail.total_price).toFixed(0) : 0
            this.form.price_for_withholding = this.booking_detail.booking_car_types.reduce((a, b) => a + (b.cost - b.expenses.reduce((c,d) => c + (d.amount * d.price), 0) ), 0);
            this.form.remark = this.booking_detail.remark;
            this.form.created_at = dayjs(this.booking_detail.created_at);
            this.form.take_order_at = dayjs(this.booking_detail.created_at).add(30, 'days');
            this.form.booking_no = this.booking_detail.booking_no;
            this.form.start_location_name = this.booking_detail.start_location_name;
            this.form.end_location_name = this.booking_detail.end_location_name;
            this.form.remark_more = this.booking_detail.remark_more;

            this.form.temporary_coordinator = this.booking_detail.temporary_coordinator;
            this.form.temporary_coordinator_phone_no = this.booking_detail.temporary_coordinator_phone_no;
            this.form.list_id_remark = JSON.parse(this.booking_detail.list_id_remark);
            
            this.remark_detail_all = this.remark_detail_all.filter((item) => item.remark_type != null)
            this.remark_detail_all.forEach((e) => {
                if (!this.remark_detail.find((f) => f.id == e.id)) {
                    this.remark_select.push(e);
                }
            });

            this.form.extras = this.booking_detail.extras

            const { client_id, client_contact_id, sale_id } = this.booking_detail

            if (client_id) {
                this.form.client_id = client_id
                this.form.client = this.client_list.find(({ id }) => id == client_id)
            }

            if (client_contact_id) {
                const target = this.getClientContactList(this.client_contact_list).find(({ id }) => id == client_contact_id)
                if (target) {
                    this.form.client_contact_id = client_contact_id
                    this.form.client_contact = target
                } else {
                    this.form.client_contact_id = null
                }
            }

            // if (sale_id) {
            //     this.onSaleChanged(sale_id)
            // }

            if (this.booking_detail.qt_setting) {
                const isDate = ['created_at','take_order_at']
                const excludedCars = ['booking_cars','booking_car_types', 'client_contact']
                const setting = JSON.parse(this.booking_detail.qt_setting)
                const selected_remarks_list = setting.selected_remarks_list
                
                for (var key in setting) {
                    if (isDate.includes(key)) {
                        this.form[key] = dayjs(setting[key])
                    } else if (excludedCars.includes(key)) {
                        continue
                    }
                }

                this.price_percent = setting? Number(setting.price_percent):0
                this.form.price_percent = this.price_percent
                this.form.total_price = this.form.price_for_withholding ?? 0
                this.form.pay_type = setting?.pay_type;
                this.form.pay_extend = setting?.pay_extend;
                this.form.discount = setting?.discount == NaN || setting?.discount == null || setting?.discount == undefined ? 0 : Number(setting?.discount)

                console.log("form", this.form);

                if (selected_remarks_list) {
                    this.form.selected_remarks_list = selected_remarks_list
                }
            }

            this.cal_price(this.form.pay_type)
            this.form.inc_vat = this.booking_detail.inc_vat? true : false;
            this.form.withholding = this.booking_detail.withholding? true : false;
            // this.form.discount = this.booking_detail.discount

            // console.log(this.form);
            this.bankName()
            this.auto_fill_car_company()
            await this.init_remark()

            this.$message.success({
                content: 'โหลดข้อมูลเรียบร้อย',
                key: 'init_options',
                duration: 2
            });
        },
        save_settings() {
            const id = this.booking_detail.id
            const setting = JSON.stringify(this.form)
            SystemService.update_all('Booking', { data: { qt_setting: setting } }, id)
                .then(res => {
                    this.$message.success({
                        content: 'บันทึกข้อมูลเรียบร้อย',
                        key: 'save_settings',
                        duration: 2
                    });
                })
        },
        withholding_type(car_company, type) {
            if (car_company) {
                const { withholding_id } = car_company
                if (withholding_id == 1) {
                    return type == 'text'? `(1%)`: 0.01
                } else if (withholding_id == 2) {
                    return type == 'text'? `(5%)`: 0.05
                } else {
                    return type == 'text'? `(3%)`: 0.03
                }
            }
        },
        onSaleChanged(v){
            this.selected_sale_id = v
            this.form.sale = this.sale_list.find(({ id }) => id == this.selected_sale_id)
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url , {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        // async export_pdf(){
        //     this.fetchChildData();
        //     this.calAllTotalPrice();
        //     console.log("exportPdf");
        //     // const doc = new jsPDF('p', 'pt', 'a4', true);
        //     const doc = new jsPDF({
        //         orientation: 'p',
        //         unit: 'pt',
        //         format: 'a4',
        //         putOnlyUsedFonts: true,
        //         lineHeight: 1.2,
        //     });
        //     const width = doc.internal.pageSize.getWidth();
        //     const height = doc.internal.pageSize.getHeight();
            
        //     //Header
        //     const imglogoSc = await this.getBase64ImageFromURL(this.form.car_company.logoSc);
        //     const imglogoIso = await this.getBase64ImageFromURL(this.form.car_company.logoIso);
        //     const imglogo = await this.getBase64ImageFromURL(this.form.car_company.logo);
        //     doc.addImage(imglogoSc, 'JPEG', 20, 15, 45 , 45);
        //     doc.addImage(imglogoIso, 'JPEG', 70, 15, 110 , 45);
        //     doc.addImage(imglogo, 'JPEG', width - 180 , 15, 160 , 80);
            
        //     doc.setFontSize(13);
        //     // doc.setFont('NotoSansThai-Bold','bold');
        //     doc.setFont('NotoSansThai-Regular','normal');
        //     doc.text(25, 75, this.form.car_company.fullname);

        //     doc.setFontSize(9);
        //     let textHeader = 
        //     `${ this.form.car_company.address }\n` +
        //     `เลขประจำตัวผู้เสียภาษี : ${ this.form.car_company.tax_id } \n` +
        //     `โทรศัพท์ : ${ this.form.car_company.phone_no } อีเมล : ${ this.form.car_company.email } Line ID: ${ this.form.car_company.line_id }\n`
        //     let splitTitle = doc.splitTextToSize(textHeader, width - 230);
        //     doc.text(25, 90, splitTitle);

        //     // Divider
        //     doc.setLineWidth(0.5);
        //     doc.line(25, 125, width - 25, 125);
        //     // End Header


        //     let columns = ["ID", "Name", "Country"];
        //     let rows = [
        //         [1, "Shaw", "Tanzania"],
        //         [2, "Nelson", "Kazakhstan"],
        //         [3, "Garcia", "Madagascar"],
        //         [3, "Garcia", "Madagascar"],
        //     ];
        //     doc.autoTable(columns, rows, {
        //         startY: 150,
        //         margin: { top: 80, right: 25, bottom: 25, left: 25 },
        //     });




        //     // doc.rect(10, 40, 120, 50);
        //     // doc.rect(130, 40, 70, 50); 

        //     // let text = 
        //     //     `เรียน : ${ this.form.client.fullname } \n` +
        //     //     `เลขประจำตัวผู้เสียภาษี : ${ this.form.client.tax_id } \n` +
        //     //     `ที่อยู่ : ${ this.form.client.address } \n` +
        //     //     `ชื่อผู้จองรถ : ${ this.form.client_contact? this.form.client_contact.firstname:'-' } \n` +
        //     //     `เบอร์โทรผู้จองรถ : ${ this.form.client_contact?.phone_no } \n` +
        //     //     `อีเมลผู้จองรถ : ${ this.form.client_contact?.email } \n` +
        //     //     `ผู้ประสานงานหน้างาน : ${ this.form.temporary_coordinator } \n` + 
        //     //     `เบอร์โทรผู้ประสานงานหน้างาน : ${ this.form.temporary_coordinator_phone_no } \n`
        //     // let splitTitle = doc.splitTextToSize(text, 120);
        //     // doc.setFontSize(10);
        //     // doc.text(12, 50, splitTitle);

        //     doc.save("Quotation.pdf");
        // }
        async export_pdf() {
            message.loading({
                content: 'กำลังสร้าง PDF...',
                key: 'export_pdf',
                duration: 0
            });
            // Fetch data and calculate totals
            this.fetchChildData();
            this.calAllTotalPrice();
            console.log("ExportPdf");

            const doc = new jsPDF({
                orientation: 'p',
                unit: 'pt',
                format: 'a4',
                putOnlyUsedFonts: true,
                lineHeight: 1.2,
            });

            const width = doc.internal.pageSize.getWidth();
            const height = doc.internal.pageSize.getHeight();

            // Convert images to Base64
            const imglogoSc = await this.getBase64ImageFromURL(this.form.car_company.logoSc);
            const imglogoIso = await this.getBase64ImageFromURL(this.form.car_company.logoIso);
            const imglogo = await this.getBase64ImageFromURL(this.form.car_company.logo);
            const imgSignature = this.form.sale ? await this.getBase64ImageFromURL(this.form.sale.signature) : null;
            // const imgSignature = await this.getBase64ImageFromURL(this.form.car_company.logoIso);
            const imgQrPayment = this.form.car_company.image_payment ? await this.getBase64ImageFromURL(this.form.car_company.image_payment) : null;

            // Header function to be reused on each page
            const addHeader = (doc, width ) => {
                    doc.addImage(imglogoSc, 'JPEG', 20, 15, 45, 45);
                    doc.addImage(imglogoIso, 'JPEG', 70, 15, 110, 45);
                    doc.addImage(imglogo, 'JPEG', width - 180, 15, 160, 80);

                    doc.setFontSize(13);
                    doc.setTextColor(38, 38, 38);
                    doc.setFont('NotoSansThai-Regular', 'normal');
                    doc.text(this.form.car_company.fullname,25, 75);

                    doc.setFontSize(9);
                    doc.setTextColor(80, 80, 80);
                    const textHeader =
                    `${this.form.car_company.address}\n` +
                    `เลขประจำตัวผู้เสียภาษี : ${this.form.car_company.tax_id}\n` +
                    `โทรศัพท์ : ${this.form.car_company.phone_no} อีเมล : ${this.form.car_company.email} Line ID: ${this.form.car_company.line_id}\n`;
                    const splitTitle = doc.splitTextToSize(textHeader, width - 230);
                    doc.text(splitTitle , 25, 90);

                    doc.setLineWidth(0.5);
                    doc.line(25, 125, width - 25, 125);
            };


            // Add first page content
            doc.setFontSize(13);
            doc.setTextColor(38, 38, 38);
            doc.setFont('NotoSansThai-Regular', 'normal');
            doc.text("ใบเสนอราคา / Quotation" , width / 2, 150, { align: "center" });

            doc.rect(25,160,390,120);
            doc.rect(420,160,150,120);
            const textLeft = 
                `เรียน : ${ this.form.client.fullname ?? '-' } \n` +
                `เลขประจำตัวผู้เสียภาษี : ${ this.form.client.tax_id ?? '-' } \n` +
                `ที่อยู่ : ${ this.form.client.address ? this.form.client.address.split("\n").join(" ") : '-' } \n` +
                `ชื่อผู้จองรถ : ${ this.form.client_contact.firstname ?? '-' } \n` +
                `เบอร์โทรผู้จองรถ : ${ this.form.client_contact?.phone_no ?? '-' } \n` +
                `อีเมลผู้จองรถ : ${ this.form.client_contact?.email ?? '-' } \n` +
                `ผู้ประสานงานหน้างาน : ${ this.form.temporary_coordinator ?? '-' } \n` + 
                `เบอร์โทรผู้ประสานงานหน้างาน : ${ this.form.temporary_coordinator_phone_no ?? '-' } \n`
            const textRight = 
                `ใบเสนอราคาที่ : ${ this.form.booking_no ?? '-' } \n` +
                `วันที่ : ${ this.time_now ?? '-' } \n` +
                `วันที่ออก : ${ this.form.created_at ? this.render_date(this.form.created_at) : '-' } \n` +
                `ใช้ได้ถึง : ${ this.form.take_order_at ? this.render_date(this.form.take_order_at) : '-' } \n` +
                `ผู้ัเสนอราคา : ${ this.form.sale ? this.form.sale.firstname : this.user_profile.username } ${ this.form.sale ? this.form.sale.lastname : this.user_profile.username } \n` +
                `เบอร์โทรผู้เสนอราคา : ${ this.form.sale?.phone_no ?? '-' } \n`
            const splitTextLeft = doc.splitTextToSize(textLeft, 470);
            const splitTextRight = doc.splitTextToSize(textRight, 180);
            doc.setFontSize(10);
            doc.setTextColor(80, 80, 80);
            doc.text(splitTextLeft , 30 , 175);
            doc.text(splitTextRight , 425 , 175);

            // Content and table
            const columns = ["รายการ", "รายละเอียดงาน", "หน่วย", "ราคา/หน่วย (บาท)", "รวมเป็นเงิน (บาท)"];

            let rows = [];
            this.booking_detail.booking_car_types.forEach((item, index) => {
                const row = [
                    index + 1,
                    item.remark,
                    item.quantity ,
                    (Number((item.cost/item.quantity).toFixed(0))).toLocaleString() + '.00' ,
                    Number(item.cost).toLocaleString() + '.00'
                ];
                rows.push(row);
            });
            this.booking_detail.extras.forEach((item, index) => {
                const row = [
                    this.booking_detail.booking_car_types.length + index + 1,
                    item.extra.name,
                    item.amount,
                    Number(item.price).toLocaleString() + '.00',
                    Number(item.price * item.amount).toLocaleString() + '.00'
                ];
                rows.push(row);
            });
            const lengthBookingCar = this.booking_detail.booking_car_types.length;

            

            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 300,
                margin: { top: 135, right: 25, bottom: 35, left: 25 },
                styles : {
                    font: 'NotoSansThai-Regular',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    halign: 'center',   
                    font: 'NotoSansThai-Bold',
                    fontSize: 10,
                },
                columnStyles: {
                    fontSize: 10, 
                    0 : { halign: 'center' },
                    1 : { halign: 'left' , cellWidth: 250 },
                    2 : { halign: 'center' },
                    3 : { halign: 'center' },
                    4 : { halign: 'center' },
                },
                theme: 'plain',
                didDrawCell: function (data) {
                    doc.setDrawColor(118, 112, 112);
                    doc.setLineWidth(0.5);

                    // Draw left and right borders
                    doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                    doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);

                    // Draw top border
                    if (data.row.index === 0) {
                        doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
                    }

                    // Draw bottom border for the last row
                    if (data.row.index === rows.length - 1) {
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }

                    // Draw bottom border for the last row of lengthBookingCar
                    if (data.row.index === lengthBookingCar - 1) {
                        doc.setLineWidth(0.5);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }

                    // Draw underline for header when it appears on a new page
                    if (data.row.section === 'head') {
                        doc.setLineWidth(0.5);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }

                    // Draw bottom border for the last row before a new page starts
                    if (data.row.index === lengthBookingCar - 1 && data.row.section === 'body' && data.row.raw[0] === lengthBookingCar) {
                        doc.setLineWidth(0.5);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }

                    // Draw row 8 bottom border
                    if (data.row.index + 1 === 8) {
                        doc.setLineWidth(1);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }

                },
            });


            // Add content after table
            let finalY = doc.lastAutoTable.finalY;
            
            
            const textBankLeft = 
                `ชื่อธนาคาร : \n` +
                `สาขา : \n` +
                `ชื่อบัญชี : \n` +
                `เลขที่บัญชี : \n`
                const textBankRight = 
                `${ this.bank_name ?? '-' } \n` +
                `${ this.form.car_company.bank_branch ?? '-' } \n` +
                `${ this.form.car_company.bank_account_name ?? '-' } \n` +
                `${ this.formattedAccountNumber() } \n`

                const vatName = !this.form.withholding && this.form.inc_vat ? `ภาษีมูลค่าเพิ่ม 7% : \n` : ''
                const textTotalLeft = 
                `รวมเป็นเงิน : \n` +
                `ส่วนลด : \n` +
                vatName +
                `รวมเป็นเงินสุทธิ : \n` +
                `(${ this.price_to_word(this.total_price_final.toLocaleString()) }) \n`
                
                const vatValue = !this.form.withholding && this.form.inc_vat ? `${ this.total_vat_seven_percent.toLocaleString() + '.00' } \n` : ''
                const textTotalRight = 
                `${ this.total_price.toLocaleString() + '.00' } \n` +
                `${ this.form.discount ? this.discount.toLocaleString() + '.00' : '0.00' } \n` +
                vatValue + 
                `${ this.total_price_final.toLocaleString() + '.00' } \n`
                
                doc.setFontSize(10);
                
                const splitTextBankLeft = doc.splitTextToSize(textBankLeft, 200);
                const splitTextTotalLeft = doc.splitTextToSize(textTotalLeft, 200);

                if(height - finalY < 200){
                    doc.addPage();
                    finalY = 150;
                }
                doc.rect(25,finalY , width / 2 - 19 , 63);
                doc.rect(width / 2 + 6 , finalY, width / 2 - 31 , 63);
                doc.setFont('NotoSansThai-Bold','bold');
                doc.setTextColor(36, 36, 36);
                doc.text(splitTextBankLeft , 28 , finalY + 10);
                doc.text(splitTextTotalLeft , width / 2 + 12 , finalY + 10);
                
            const splitTextBankRight = doc.splitTextToSize(textBankRight, 200);
            const splitTextTotalRight = doc.splitTextToSize(textTotalRight, 200);

            doc.setFont('NotoSansThai-Regular','normal');
            doc.setTextColor(80, 80, 80);
            doc.text(splitTextBankRight , width / 2 + 3, finalY + 10 , { align: "right" });
            doc.text(splitTextTotalRight , width - 25, finalY + 10 , { align: "right" });

            if(this.form.withholding){
                doc.rect(25,finalY + 63 , width - 50 , 15, 'S');
                doc.setTextColor(80, 80, 80);
                doc.text(`สามารถหักภาษี ณ ที่จ่าย ${this.withholding_type(this.form.car_company, 'text')} (ค่าขนส่ง) : ` +
                `${ this.total_vat_one_percent.toLocaleString() + '.00' } บาท` , width - 27 , finalY + 73  , { align: "right" });
            }

            let rowsRemarks = [];
            this.remark_types_all.forEach((item, index) => {
                let detail = '';
                if(this.filter_remark(item.id).length == 0){
                    detail = '-';
                }else{
                    this.filter_remark(item.id).forEach((item2, index2) => {
                        if(index2 + 1 == this.filter_remark(item.id).length){
                            detail += `     ${index2 + 1}. ${item2.remark}`
                        }else{
                            detail += `     ${index2 + 1}. ${item2.remark} \n`
                        }
                    })
                }

                const row = [
                    item.remark_name + '\n' + detail,
                ];
                rowsRemarks.push(row);
            });

            // เงื่อนไขการขนส่ง
            if(height - finalY < 200){
                doc.addPage();
                finalY = 80;
            }
            doc.setFontSize(10);
            doc.setTextColor(36, 36, 36);
            doc.setFont('NotoSansThai-Bold','bold');
            doc.text("เงื่อนไขการขนส่ง", 25, finalY + 95);

            doc.autoTable({
                body: rowsRemarks,
                startY: finalY + 100,
                margin: { top: 135, right: 25, bottom: 35, left: 25 },
                styles : {
                    font: 'NotoSansThai-Regular',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    halign: 'center',   
                    font: 'NotoSansThai-Bold',
                },
                columnStyles: {
                    // 0 : { halign: 'center', cellWidth: 50 },
                    1 : { halign: 'left',cellWidth: 150 },
                    2 : { halign: 'left' },
                },
               theme: 'plain'
            });

            //หมายเหตุเพิ่มเติม
            doc.setFontSize(10);
            doc.setTextColor(36, 36, 36);
            doc.setFont('NotoSansThai-Bold','bold');        
            doc.text("หมายเหตุเพิ่มเติม", 25, doc.lastAutoTable.finalY + 20);
            doc.autoTable({
                body: [[this.form.remark_more ?? '-']],
                startY: doc.lastAutoTable.finalY + 30,
                margin: { top: 135, right: 25, bottom: 35, left: 25 },
                styles : {
                    font: 'NotoSansThai-Regular',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    halign: 'center',   
                    font: 'NotoSansThai-Bold',
                },
                columnStyles: {
                    0 : { halign: 'left' },
                },
                theme: 'plain'
            });

            let docFinalY = doc.lastAutoTable.finalY

            if(height - doc.lastAutoTable.finalY < 200){
                doc.addPage();
                docFinalY = 150;
            }
            
            doc.rect(25,docFinalY + 20 , width - 50 , 140);

            const signature = 
                `ลงชื่อ..............................................................\n\n` +
                `(.....................................................................)`
            const textNameSignatureLeft = 
                `ผู้มีอำนาจลงนาม\n` +
                `${ this.form.client.fullname }`
            const textNameSignatureRight = 
                `ผู้เสนอราคา\n\n` +
                `${ this.form.car_company.fullname }`

            const textSignature = doc.splitTextToSize(signature, width / 2);
            const splitTextNameSignatureLeft = doc.splitTextToSize(textNameSignatureLeft, 250);
            const splitTextNameSignatureRight = doc.splitTextToSize(textNameSignatureRight, 250);

            doc.setFontSize(10);
            doc.setFont('NotoSansThai-Regular','normal');

            if(imgSignature){
                doc.addImage(imgSignature, 'JPEG',  width / 2 + 100 , docFinalY + 30, 110, 50);
            }
            doc.text(textSignature , width / 4  , docFinalY + 85 , { align: "center" });
            doc.text(splitTextNameSignatureLeft , width / 4 , docFinalY + 125 , { align: "center" });

            
            doc.setLineDashPattern([2, 2], 1);
            doc.rect( width / 2 - 25 , docFinalY + 70 , 50 , 50 );
            doc.text("ประทับตรา", width / 2 , docFinalY + 100 , { align: "center" });

            doc.text(textSignature , width / 2 +  width / 4 , docFinalY + 85 , { align: "center" });
            doc.text(splitTextNameSignatureRight , width / 2 +  width / 4 , docFinalY + 125 , { align: "center" });
            
            docFinalY += 180;

            if(imgQrPayment != null){
                // if(height - docFinalY < 230 && imgQrPayment != null){
                doc.addPage();
                docFinalY = 130;
                doc.addImage(imgQrPayment, 'JPEG',  25 , docFinalY + 20, 420, 160);
                // }
            }

            // นับจำนวนหน้า แล้วใส่ Header และ Footer ใหม่ ทุกหน้า
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.setLineDashPattern([0, 0], 0);
                addHeader(doc, width);

                //เส้นคั่นสีดำ Footer
                doc.setLineWidth(0.5);
                doc.setDrawColor(0, 0, 0);
                doc.line(25, doc.internal.pageSize.height - 35, width - 25, doc.internal.pageSize.height - 35);
                doc.setFontSize(8);
                doc.setTextColor(80, 80, 80);
                doc.text( this.car_company_list[0].fullname_en , 25, doc.internal.pageSize.height - 20);
                if(this.car_company_list[0].company_id == 26){
                    doc.text( "F-CS-0002 ฉบับแก้ไขครั้งที่ 2 วันที่อนุมัติใช้ 1 มิถุนายน 2560" , width - 25 , doc.internal.pageSize.height - 20 , { align: "right" });
                }
            }
            
            doc.save(`Quotation-${this.booking_detail.booking_no}.pdf`);
            message.success({
                content: 'สร้าง PDF เรียบร้อย',
                key: 'export_pdf',
                duration: 2
            });
        },
    },
    mounted() {
        this.init_options()
        console.log("this.booking_detail" , this.booking_detail);
    }
}
</script>

<style scoped>
p {
    margin: 0;
    color: black !important;
}

h1 {
    font-size: 26px;
    margin: 5px 0;
}

h2 {
    font-size: 18px;
    margin: 5px 0;
}

.doc-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 20px;
}

tr,
td {
    padding: 0px 5px;
}

.showing-for-dev {
    display: block;
    background: white;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border: 2px solid black;
    height: 100vh;
    overflow-y: scroll;
}

.ant-row {
    margin-bottom: 8px;
}

p {
    color: grey
}

.ant-col-12 {
    min-width: 300px;
    padding: 4px
}

.ant-select,
.ant-picker {
    width: 100%
}

.ant-col {
    width: 100%
}

.ant-form-item {
    margin-bottom: 0;
}

.quotation-container {
    padding: 15px;
    height: 100vh;
}
</style>