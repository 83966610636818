<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card title="สมัครแอดมิน">
                <div style="display: flex; justify-content: center;">
                    <div class="login-form flex-column">
                        <a-form 
                            :model="formState" name="register-form" 
                            :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
                            autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
                            <a-divider orientation="left">Company Type</a-divider>
                            <a-form-item label="ประเภทบริษัท">
                                <a-radio-group v-model:value="formState.tax_id">
                                    <a-radio value="Tour">Tour</a-radio>
                                    <a-radio value="Garage">Garage</a-radio>
                                    <a-radio value="Non-Commercial">Non-Commercial</a-radio>
                                    <a-radio value="Commercial">Commercial</a-radio>
                                </a-radio-group>
                            </a-form-item>

                            <a-form-item label="ชื่อบริษัท/ลูกค้า" name="company_name"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_name" />
                            </a-form-item>

                            <a-form-item label="เลขที่ผู้เสียภาษี" name="company_tax_id"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_tax_id" />
                            </a-form-item>

                            <a-form-item label="โลโก้บริษัท">
                                <a-form-item name="company_logo" no-style>
                                    <a-upload v-model:file-list="fileList" list-type="picture-card"
                                            class="avatar-uploader"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, 'company_logo')" method="GET"
                                            :before-upload="setFileType"
                                            accept="image/*"
                                            @remove="remove_uploaded_file($event)">
                                            <img v-if="formState['company_logo']" :src="formState['company_logo']"
                                                :style="`width: 100%;object-fit: cover`"
                                                alt="avatar" />
                                            <div v-else>
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined"
                                                        style="font-size: 5rem">upload_file</span>
                                                </p>
                                                คลิกเพื่ออัพโหลดรูปภาพ
                                            </div>
                                    </a-upload>
                                </a-form-item>
                            </a-form-item>

                            <a-form-item label="ที่อยู่บริษัท" name="company_address"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-textarea v-model:value="formState.company_address" />
                            </a-form-item>

                            <a-form-item label="ที่อยู่เรียกเก็บเงินของบริษัท" name="company_billing_address"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-textarea v-model:value="formState.company_billing_address" />
                            </a-form-item>

                            <a-form-item label="เบอร์โทรศัพท์ของบริษัท" name="company_phone_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_phone_no" />
                            </a-form-item>

                            <a-form-item label="ชื่อธนาคาร" name="company_bank"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-select v-model:value="formState.company_bank">
                                    <a-select-option v-for="option in formState.banks" :key="option.id" :value="option.id">
                                        {{ option.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item label="ชื่อสาขาของธนาคาร" name="company_bank_branch"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_branch" />
                            </a-form-item>

                            <a-form-item label="ชื่อบัญชี" name="company_bank_acc_name"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_acc_name" />
                            </a-form-item>

                            <a-form-item label="เลขที่บัญชี" name="company_bank_acc_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_acc_no" />    
                            </a-form-item>

                            <a-divider orientation="left">Company owner account (ADMIN)</a-divider>

                            <a-form-item label="ชื่อ" name="admin_firstname"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_firstname" />
                            </a-form-item>

                            <a-form-item label="นามสกุล" name="admin_lastname"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_lastname" />
                            </a-form-item>

                            <a-form-item label="อีเมล" name="admin_email"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_email" />
                            </a-form-item>

                            <a-form-item label="เบอร์โทรศัพท์" name="admin_phone_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_phone_no" />
                            </a-form-item>

                            <a-tooltip placement="top">
                                <template #title>
                                    <span>ใช้สำหรับเข้าสู่ระบบ</span>
                                </template>
                                <a-form-item label="ชื่อบัญชีผู้ใช้" name="admin_username"
                                :rules="[
                                          {
                                            required: true,
                                            message: 'โปรดกรอกข้อมูลของคุณ',
                                          },
                                          {
                                            validator: validate_duplicate,
                                            trigger: 'change',
                                          },
                                        ]"
                                 >
                                  <a-input v-model:value="formState.admin_username" />
                                </a-form-item>
                            </a-tooltip>

                            <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                                <a-button type="primary" html-type="submit">สมัครสมาชิก</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import input_primary from '../components/input_primary.vue'
import UserService from '../api/UserService.js'
import BankService from '../api/BankService.js'
import { defineComponent, reactive } from 'vue';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons-vue';
import SystemService from '../api/SystemService';

export default defineComponent({
    components: {
        input_primary,
        UploadOutlined,
        InboxOutlined
    },
    name: 'Login',
    data() {
        return {
            cover_img: require('@/assets/images/hiso-bus-rental-bg-homepage.jpg'),
            logo_img: require('@/assets/images/vecabo-label.png')
        }
    },
    setup() {
        const fileList = []

        const formState = reactive({
            company_name: null,
            company_type: null,
            company_tax_id: null,
            company_logo: null,
            company_address: null,
            company_billing_address: null,
            company_phone_no: null,
            company_bank: null,
            company_bank_branch: null,
            company_bank_acc_name: null,
            company_bank_acc_no: null,
            banks: [],
            //Admin
            admin_firstname: null,
            admin_lastname: null,
            admin_email: null,
            admin_phone: null,
            admin_username: null,
            admin_password: null,
            admin_confirm_password: null
        });

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const get_company_bank = async () => {
            try {
                const res = await BankService.getAll();
                formState.banks = res.data;
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
        };

        return {
            formState,
            onFinishFailed,
            fileList,
            get_company_bank
        };
    },
    methods: {
        async get_company_bank() {
            for (var key in this.formState.company_bank.inputs) {
                const { input_type, modelname} = this.formState.company_bank.inputs[key]
                if ((input_type == 'select') && modelname) {
                    if (modelname == 'Bank') {
                        const res = await BankService.getAll()
                        this.formState.company_bank[modelname] = res.data
                        console.log("res", res);
                    } 
                }
            }
        },
        async remove_uploaded_file({ uid }) {
            this.formState[uid] = ''
        },
        setFileType(file) {
            const fileType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' 
            || file.type === 'image/raw' || file.type === 'image/webp' || file.type === 'image/tif' || file.type === 'image/psd' || file.type === 'image/svg';
            if (!fileType) {
                this.$message.error('คุณสามารถอัปโหลดได้เฉพาะไฟล์รูปภาพเท่านั้น!');
            }
            return fileType
        },
        upload_files(event, field) {
            const vue = this
            const file = event.file.originFileObj
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                vue.formState[field] = reader.result
            }

            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        onFinish(values) {
            this.$emit('register_value', values)
        },
        async sign_in() {
            const data = await UserService.login({ username: this.username, password: this.password })
            if (data.token) {
                localStorage.setItem('token', data.token)

                location.reload()
            }
        },
      async validate_duplicate(_rule, value) {
        return new Promise((resolve, reject) => {
          if (_rule.required && !value) {
            reject("โปรดระบุ");
          }
          if (!value) {
            reject();
          }
          setTimeout(() => {
            SystemService.check_duplicate(
              "Admin",
              `field=username&value=${value}&id=create`
            ).then((res) => {
              if (res) {
                reject("มีข้อมูลนี้อยู่แล้ว");
              } else {
                resolve();
              }
            });
          }, 0);
        });
      },
    },
    mounted() {
        this.get_company_bank()
    }
})
</script>

<style lang="scss">
label,
h2 {
    font-weight: 400 !important;
}

.login-container {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 992px) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

.form-card-img {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    background-position: 50% 50%;

    @media (min-width: 992px) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
</style>
