<template>
    <div class="calendar-container">
      <a-card>
        <template #title>
          <div style="display: flex; justify-content: space-between">
            <span>ตารางการใช้รถ</span>
            <span
              >ประเภทรถ: {{ showSelectedCarType }} | ทั้งหมด:
              {{ resourcesDetails.total }} | จองแล้ว:
              {{ resourcesDetails.haveEvents }} | ว่าง:
              {{ resourcesDetails.noEvents }}</span
            >
          </div>
        </template>
        <a-row justify="space-between">
          <a-col>
            <a-select
              style="width: 120px"
              v-model:value="date_type"
              @change="calendar_change"
              placeholder="เลือกเดือน"
            >
              <a-select-option value="Day">Day</a-select-option>
              <a-select-option value="Week">Week</a-select-option>
              <a-select-option value="Month">Month</a-select-option>
              <a-select-option value="Custom">Custom</a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-date-picker
              v-if="date_type == 'Day'"
              style="width: 200px"
              v-model:value="date_day"
              placeholder="เลือกวัน"
              :locale="lang_th"
              format="DD MMM YYYY"
            />
            <a-date-picker
              v-if="date_type == 'Week'"
              style="width: 200px"
              v-model:value="date_week"
              picker="week"
              placeholder="เลือกสัปดาห์"
              :locale="lang_th"
              format="DD MMMM YYYY"
            />
            <a-date-picker
              v-if="date_type == 'Month'"
              style="width: 200px"
              v-model:value="date_month"
              picker="month"
              placeholder="เลือกเดือน"
              :locale="lang_th"
              format="MMMM YYYY"
            />
            <a-range-picker
              v-if="date_type == 'Custom'"
              v-model:value="date_range"
              @change="handleCustomChange"
              :placeholder="['วันเริ่มต้น', 'วันสิ้นสุด']"
            />
          </a-col>
          <a-col>
            <a-select
              style="width: 450px"
              v-model:value="selected_company"
              placeholder="ค้นหาบริษัท"
            >
              <a-select-option
                v-for="({ id, fullname }, i) in companies"
                :key="i"
                :value="id"
                >{{ fullname }}</a-select-option
              >
            </a-select>
          </a-col>
  
          <!-- รายละเอียดรถ -->
          <a-col>
            <a-dropdown v-model:visible="car_filter_visible" :trigger="['click']">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1">
                    <span>1.ประเภทรถ</span>
                    <a-select
                      style="width: 280px"
                      v-model:value="car_type_id"
                      placeholder="ประเภทรถ"
                    >
                      <a-select-option
                        v-for="({ id, name }, i) in car_type_list"
                        :key="i"
                        :value="id"
                        >{{ name }}
                      </a-select-option>
                    </a-select>
                  </a-menu-item>
                  <a-menu-item key="2">
                    2.รหัสรถ
                    <a-input
                      style="width: 280px"
                      v-model:value="car_no"
                      placeholder="รหัสรถ"
                    />
                  </a-menu-item>
                  <a-menu-item key="3">
                    3.รุ่นรถ
                    <a-input
                      style="width: 280px"
                      v-model:value="car_model"
                      placeholder="รุ่นรถ"
                    />
                  </a-menu-item>
                  <a-menu-item key="4">
                    4.ยี่ห้อรถ
                    <a-input
                      style="width: 280px"
                      v-model:value="car_brand"
                      placeholder="ยี่ห้อรถ"
                    />
                  </a-menu-item>
                  <a-menu-item key="5">
                    5.ทะเบียนรถ
                    <a-input
                      style="width: 280px"
                      v-model:value="plate_no"
                      placeholder="ทะเบียนรถ"
                    />
                  </a-menu-item>
                  <a-menu-item key="6">
                    6.วันหมดอายุพรบ.รถ
                    <a-range-picker
                      style="width: 280px"
                      v-model:value="car_tax_expire"
                      placeholder="วันหมดอายุพรบ.รถ"
                      format="DD/MM/YYYY"
                    />
                  </a-menu-item>
                  <a-menu-item key="7">
                    7.วันหมดอายุประกันภัยรถ
                    <a-range-picker
                      style="width: 280px"
                      v-model:value="insure_expire"
                      placeholder="วันหมดอายุประกันภัยรถ"
                      format="DD/MM/YYYY"
                    />
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button style="width: 225px">
                <div
                  v-if="!car_filter_title"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <span style="color: grey">ข้อมูลรถ</span>
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 16px; color: grey"
                    >directions_car</span
                  >
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                {{ car_filter_title }}
                </div>
              </a-button>
            </a-dropdown>
          </a-col>
  
          <a-select
            show-search
            style="width: 200px"
            v-model:value="driver_id"
            placeholder="ค้นหาชื่อผู้ขับ"
            :filterOption="filterOption"
          >
            <a-select-option
              v-for="({ id, firstname, lastname }, i) in driver_list"
              :key="`${firstname} ${lastname}`"
              :value="id"
              >{{ `${firstname} ${lastname}` }}
            </a-select-option>
          </a-select>
  
          <a-col>
            <a-button type="primary" @click="clear_filter">ล้าง</a-button>
          </a-col>
        </a-row>
        <br />
        <div id="calendar"></div>
      </a-card>
    </div>
  </template>
  
  <script>
  import dayjs from "dayjs";
  import buddhistEra from "dayjs/plugin/buddhistEra";
  dayjs.extend(buddhistEra);
  // var isBetween = require("dayjs/plugin/isBetween");
  import isBetween from "dayjs/plugin/isBetween";
  dayjs.extend(isBetween);
  import CalendarService from "../../api/CalendarService";
  import SystemService from "../../api/SystemService";
  import UserService from "../../api/UserService";
  import lang_th from "ant-design-vue/es/date-picker/locale/th_TH";
  
  import { Calendar } from "@fullcalendar/core";
  import thLocale from "@fullcalendar/core/locales/th";
  import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
  
  import { Select } from "ant-design-vue";
import { h } from "vue";
  
  export default {
    name: "Schedules",
    components: {
      "a-select": Select,
      "a-select-option": Select.Option,
    },
    data() {
      return {
        selected_company: null,
        date_type: "Month",
        date_day: dayjs(),
        date_week: dayjs(),
        date_month: dayjs(),
        calendar_range: [],
        date_range: [],
        car_filter_visible: false,
        car_filter_list: [],
        companies: [],
        resources: [],
        events: [],
        // colors
        colors: [],
        // color map to store color for each event ID
        colorMap: {},
        // holidays
        holidays: [
          "01-01", // วันขึ้นปีใหม่
          "02-10", // วันมาฆบูชา (อาจเปลี่ยนแปลงทุกปี)
          "04-06", // วันจักรี
          "04-13", // วันสงกรานต์
          "04-14", // วันสงกรานต์
          "04-15", // วันสงกรานต์
          "05-01", // วันแรงงาน
          "05-05", // วันฉัตรมงคล (อาจเปลี่ยนแปลง)
          "05-13", // วันพืชมงคล (อาจเปลี่ยนแปลงทุกปี)
          "06-03", // วันเฉลิมพระชนมพรรษาสมเด็จพระนางเจ้าฯ พระบรมราชินี
          "07-28", // วันเฉลิมพระชนมพรรษาพระบาทสมเด็จพระเจ้าอยู่หัว
          "08-12", // วันแม่แห่งชาติ
          "10-13", // วันคล้ายวันสวรรคต ร.9
          "10-23", // วันปิยมหาราช
          "12-05", // วันพ่อแห่งชาติ
          "12-10", // วันรัฐธรรมนูญ
          "12-31", // วันสิ้นปี
        ],

        car_type_list: [],
        driver_list: [],
        calendar: null,
        lang_th: lang_th,
        car_type_id: null,
        car_no: null,
        car_model: null,
        car_brand: null,
        plate_no: null,
        // car_tax_no: null,
        // car_insure_no: null,
        car_tax_expire:null,
        insure_expire:null,
        driver_id: null,
        calendar_view_name: {
          day: "ResourceTimelineDay",
          week: "ResourceTimelineWeek",
          month: "ResourceTimelineMonth",
        },
      };
    },
    computed: {
      resourcesDetails() {
        return {
          total: this.resources?.length,
          haveEvents: this.resources?.filter(
            (resource) =>
              this.events?.filter(({ resourceId }) => resourceId == resource.id)
                .length > 0
          ).length,
          noEvents: this.resources?.filter(
            (resource) =>
              this.events?.filter(({ resourceId }) => resourceId == resource.id)
                .length == 0
          ).length,
        };
      },
      showSelectedCarType() {
        const target = this.car_type_list.find(
          (car_type) => car_type.id == this.car_type_id
        );
  
        return target ? target.name : "ยังไม่ได้เลือก";
      },
      datetime() {
        if (this.date_type == "Day") {
          return this.date_day;
        } else if (this.date_type == "Week") {
          return dayjs(this.date_week, "DD MMMM YYYY").format("DD/MM/YYYY");
        } else if (this.date_type == "Month") {
          return dayjs(this.date_month, "MMMM YYYY").format("DD/MM/YYYY");
        } else {
          return dayjs().format("DD/MM/YYYY");
        }
      },
      car_filter_title() {
        this.car_filter_list = [];
        this.driver_filter = [];
        if (this.car_type_id) {
          this.car_filter_list.push({
            key: "car_type_id",
            value: this.car_type_id,
            label: `ประเภทรถ: ${
              this.car_type_list.find(
                (car_type) => car_type.id == this.car_type_id
              )?.name
            }`,
          });
        }
  
        if (this.car_no) {
          this.car_filter_list.push({
            key: "car_no",
            value: this.car_no,
            label: `รหัสรถ: ${this.car_no}`,
          });
        }
  
        if (this.car_model) {
          this.car_filter_list.push({
            key: "car_model",
            value: this.car_model,
            label: `รุ่นรถ: ${this.car_model}`,
          });
        }
  
        if (this.car_brand) {
          this.car_filter_list.push({
            key: "car_brand",
            value: this.car_brand,
            label: `ยี่ห้อรถ: ${this.car_brand}`,
          });
        }
        if (this.plate_no) {
          this.plate = this.plate_no;
          this.car_filter_list.push({
            key: "plate_no",
            value: this.plate_no,
            label: `ทะเบียนรถ: ${this.plate_no}`,
          });
        }
        if (this.car_tax_expire) {
          console.log(this.car_tax_expire)
          this.car_filter_list.push({
            key: "car_tax_expire",
            value: this.car_tax_expire,
            label: `พรบ.รถ: ${this.car_tax_expire}`,
          });
        }
        if (this.insure_expire) {
          this.car_filter_list.push({
            key: "insure_expire",
            value: this.insure_expire,
            label: `ประกันภัยรถ: ${this.insure_expire}`,
          });
        }
        if (this.driver_id) {
          this.car_filter_list.push({
            key: "driver_id",
            value: this.driver_id,
          });
        }
  
        return this.car_filter_list.length
          ? this.car_filter_list.map(({ label }) => label).join(", ")
          : null;
      },
    },
    methods: {
      handleCustomChange(){
        this.init_calendar();
        this.car_filter_list = [];
        this.driver_id = null;
      },
      filterOption(input, option) {
        return (
          option.key &&
          option.key.toString().toLowerCase().includes(input.toLowerCase())
        );
      },
      findDriver(id) {
        const name = this.driver_list.filter((e) => e.id == id);
        var nameDriver = `${name[0].firstname} ${name[0].lastname}`;
        return nameDriver;
      },
      clear_filter() {
        this.driver_id = null;
        this.car_filter_list = [];
        this.car_type_id = null;
        this.car_no = null;
        this.car_model = null;
        this.car_brand = null;
        this.plate_no = null;
        // this.car_tax_no = null;
        // this.car_insure_no = null;
        this.car_tax_expire = null;
        this.insure_expire = null
        this.calendar_range = [];
  
        this.get_schedule(this.selected_company, {
          startDate: dayjs(this.datetime)
            .startOf(this.date_type.toLowerCase())
            .format("DD/MM/YYYY HH:mm:ss"),
          endDate: dayjs(this.datetime)
            .endOf(this.date_type.toLowerCase())
            .format("DD/MM/YYYY HH:mm:ss"),
        });
      },
      calendar_change() {
        if (this.date_type == "Custom") {
          this.clear_filter();
        } else {
          this.calendar.changeView("resourceTimeline" + this.date_type);
          this.clear_filter();
        }
      },
      init_calendar() {
        console.log(this.resources)
        var calendarEl = document.getElementById("calendar");
        this.calendar = new Calendar(calendarEl, {
          schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
          businessHours: {
            daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
            startTime: "00:00", // a start time (10am in this example)
            endTime: "24:00", // an end time (6pm in this example)
          },
          headerToolbar: null,
          nowIndicator: false,
          plugins: [resourceTimelinePlugin],
          locale: thLocale,
          firstDay: 0,
          height: "auto",
          initialView: "resourceTimeline" + this.date_type,
          views: {
            resourceTimelineCustom: {
              type: "resourceTimeline",
              duration: { days: this.count_day_duration() },
            },
          },
          validRange: {
            start: this.calendar_range[0],
            end: this.calendar_range[1],
          },
          // resourceAreaHeaderContent: "เบอร์รถ | ทะเบียนรถ",
          resourceAreaColumns: [
            {
              field: 'title',
              headerContent: 'เบอร์รถ | ทะเบียนรถ'
            },
            {
              field: 'car_status_id',
              headerContent: 'สถานะรถ',
              cellClassNames:"",
              cellContent: (arg) => {
            const isReady = arg.fieldValue == 1;
            const color = isReady ? '#52c41a' : '#f5222d'; // Green for ready, red for not ready
            const text = isReady ? 'พร้อมใช้งาน' : 'ไม่พร้อมใช้งาน';
            return {
              html: `
                <div style="display: flex; align-items: center;">
                  <span style="display: inline-block; width: 6px; height: 6px; border-radius: 100%; background-color: ${color}; margin-right: 8px;"></span>
                  ${text}
                </div>
                `
              };
          }
            },
            {
              field: 'car_tax_expire',
              headerContent: 'วันหมดอายุพรบ.รถ',
              cellContent: (arg) => {
                // let car_tax_expire_date = dayjs(arg.fieldValue).format("DD/MM/YYYY")
                return{html: arg.fieldValue != null ? `${dayjs(arg.fieldValue).format("DD/MM/YYYY")}` : "01/01/2024"}
              }
            },
            {
              field: 'insure_expire',
              headerContent: 'วันหมดอายุประกันภัยรถ',
              cellContent: (arg) => {
                return{html: arg.fieldValue != null ? `${dayjs(arg.fieldValue).format("DD/MM/YYYY")}` : "01/01/2024"}
              }
            }
          ],
          resourceAreaWidth: "initial",
          eventBackgroundColor: "#B1E4C0",
          resources: this.resources,
          events: this.events,
          eventContent: (arg) => {
            let company = arg.event.title;
            let fullname = `${arg.event.extendedProps.driver_fname} ${arg.event.extendedProps.driver_lname}`;
            let work_status = arg.event.extendedProps.work_status;
            let time_start = dayjs(arg.event.start).format("HH:mm");
            let time_end = dayjs(arg.event.end).format("HH:mm");
            let time_accept = arg.event.extendedProps.time_accepted_work;
            let time_finish = arg.event.extendedProps.time_finished_work;
            let progress = arg.event.extendedProps.progress;
            let status = arg.event.extendedProps.status;

            let eventText = this.getTextEvent(work_status,time_accept,time_finish,company,fullname,progress,status,time_start,time_end)
            return {
              html : eventText
            }
          },
          eventClick: function (info) {
            const bookingId = info.event.extendedProps.bookingId;
            const url = `app/quotation/${bookingId}?edit=true`;
            window.open(url, "_blank");
          },
          eventDidMount: (info) => {
            const bookingId = info.event.extendedProps.bookingId;
            let color = "";
            if (info.event.extendedProps.status == 6 || info.event.extendedProps.progress == 3) {
              color = "#808080"; // Grey for cancelled events
            } else {
              color = this.getRandomColor(bookingId); // Get or assign a color for this event
            }
            info.el.style.backgroundColor = color;
            info.el.style.borderColor = color;
          },
  
          slotLabelClassNames: (arg) => {
            const dateStr = dayjs(arg.date).format("MM-DD");
            if (this.holidays.includes(dateStr)) {
              return ["holiday"];
            }
            return [];
          },
          slotLaneClassNames: (arg) => {
            const dateStr = dayjs(arg.date).format("MM-DD");
            if (this.holidays.includes(dateStr)) {
              return ["holiday"];
            }
            return [];
          },
        });
        if (this.date_type == "Custom") {
          let currentDate = dayjs(this.calendar_range[0]).toDate();
          this.calendar.gotoDate(currentDate);
        }
        this.calendar_range = [];
        this.calendar.render();
      },
      getTextEvent(work_status,time_accept,time_finish,company,name,progress,status,time_start,time_end){
        if(time_accept != null && time_finish == null){
          return `กำลังดำเนินการ  |  ${dayjs(time_accept).format("HH:mm")}  |  ${company}  |  ${name}`
        }else if(time_accept != null && time_finish != null){
          return `เสร็จสิ้น  |  ${dayjs(time_accept).format("HH:mm")} - ${dayjs(time_finish).format("HH:mm")}  |  ${company}  |  ${name}`
        }else if(progress == 3 || status == 6){
          return `ยกเลิก  |  ${company}  |  ${name}`
        }else{
          if(work_status == 7 && progress == 3 && status == 6){
            return `ยกเลิก  |  ${company}  |  <s>${name}</s>`
          }else if(work_status == 7){
            return `รอดำเนินการ  |  ${time_start} - ${time_end}  |  ${company}  |  <s>${name}</s>`
          }else{
            return `รอดำเนินการ  |  ${time_start} - ${time_end}  |  ${company}  |  ${name}`
          }
        }
      },
      getRandomColor(eventId) {
        // If the color for this event ID already exists, return it
        if (this.colorMap[eventId]) {
          return this.colorMap[eventId];
        }

        // Generate a new color only if it hasn't been assigned yet
        function getRandomInt(min, max) {
          return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        const min = 64;
        const max = 191;
        let r, g, b;
        while (true) {
          r = getRandomInt(min, max);
          g = getRandomInt(min, max);
          b = getRandomInt(min, max);
          if (Math.abs(r - g) > 30 || Math.abs(g - b) > 30 || Math.abs(r - b) > 30) {
            break;
          }
        }
        const color = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

        // Store the generated color in the colorMap for this event ID
        this.colorMap[eventId] = color;

        return color;
      },
      count_day_duration() {
        if (this.date_type == "Custom") {
          if (this.date_range?.length == 2) {
            this.calendar_range = [];
            this.calendar_range.push(dayjs(this.date_range[0]).format("YYYY-MM-DD"));
            this.calendar_range.push(dayjs(this.date_range[1]).add(2, "day").format("YYYY-MM-DD"));
          }
        }
        return (
          dayjs(this.date_range[1]).diff(dayjs(this.date_range[0]), "day") + 1
        );
      },
  
      async get_schedule(car_company_id, { startDate, endDate }) {
        this.$message.loading({
          content: "Loading...",
          key: "get_schedule",
          duration: 0,
        });
  
        try {
          if (
            ((!startDate || startDate === "Invalid Date") &&
              (!endDate || endDate === "Invalid Date")) ||
            (typeof this.datetime === "string" && this.datetime.includes("/"))
          ) {
            if (this.date_type != "Custom") {
              [startDate, endDate] = [
                dayjs(this.datetime, "DD/MM/YYYY")
                  .startOf(this.date_type.toLowerCase())
                  .format("YYYY-MM-DD HH:mm:ss"),
                dayjs(this.datetime, "DD/MM/YYYY")
                  .endOf(this.date_type.toLowerCase())
                  .format("YYYY-MM-DD HH:mm:ss"),
              ];
            } else {
              [startDate, endDate] = [
                dayjs(dayjs(startDate).format("DD/MM/YYYY"), "DD/MM/YYYY")
                  .startOf(this.date_type.toLowerCase())
                  .format("YYYY-MM-DD HH:mm:ss"),
                dayjs(dayjs(endDate).format("DD/MM/YYYY"), "DD/MM/YYYY")
                  .startOf(this.date_type.toLowerCase())
                  .format("YYYY-MM-DD HH:mm:ss"),
              ];
            }
          }
          if (this.calendar) this.calendar.loading = true;
          const { resources, events } = await CalendarService.get_schedule(
            car_company_id,
            { startDate, endDate }
          );
          this.events = events;
          this.resources = resources.map((r) => {
            r.title = `${r.car_no} | ${r.plate_no}`;
            return r;
          });

          this.init_calendar();
          if (this.calendar) this.calendar.loading = false;
          this.calendar.gotoDate(
            this.date_type === "Custom" ? startDate : endDate
          );
          this.$message.destroy("get_schedule");
        } catch (error) {
          this.date_day = null;
          if (this.calendar) this.calendar.loading = false;
          this.$message.destroy("get_schedule");
        }
      },
      async init_companies() {
        this.companies = await SystemService.get_all("CarCompany");
        this.selected_company = this.companies[0].id;
      },
      async get_car_type_list() {
        this.car_type_list = await SystemService.get_all("CarType");
      },
      async get_driver_list() {
        this.driver_list = await UserService.GetDrivers();
      },
      filter_data() {
          for (let i = 0; i < this.car_filter_list.length; i++) {
            const filter = this.car_filter_list[i];
            // console.log("filter:",filter)
            if(this.car_filter_list[i].key == 'driver_id'){
              let resourceId = []
              this.events = this.events.filter((e) => e.driver_id == filter.value);
              resourceId.push(this.events.car) //เอาเลขทะเบียนรถมาเก็บไว้
              this.events.forEach((e) => {
                resourceId.push(e.resourceId)
              });
              this.resources = this.resources.filter((e) => resourceId.includes(e.id));
            }
            else if(this.car_filter_list[i].key == 'insure_expire' || this.car_filter_list[i].key == 'car_tax_expire'){
              this.resources = this.resources.filter((e) => {
                  if (!filter || !filter.key || !filter.value) {
                    return true;
                  }

                  const key = filter.key;
                  const value = filter.value;
                  const fromDate = dayjs(value[0]).startOf('day')
                  const toDate = dayjs(value[1]).startOf('day')
                  const resourceDate = dayjs(e[key]).startOf('day')
                  return resourceDate.isValid() ? resourceDate.isBetween(fromDate, toDate, 'day','[]') : false
                });
              }
              // else if(this.car_filter_list[i].key != 'driver_id'){
                else{
                  this.resources = this.resources.filter((e) => {
                    if (!filter || !filter.key || !filter.value) {
                      return true;
                    }
                    
                    const key = filter.key;
                    const value = filter.value;
                    
                    if (typeof e[key] === "string") {
                      return e[key].toLowerCase().includes(value.toLowerCase());
                    } else {
                      return e[key] == value;
                    }
                  });
                }
          }
      },
      syncDate() {
        let startDate = "";
        let endDate = "";
        if (this.date_type != "Custom") {
          startDate = dayjs(this.datetime, "DD/MM/YYYY")
            .startOf(this.date_type.toLowerCase())
            .format("YYYY-MM-DD");
          endDate = dayjs(this.datetime, "DD/MM/YYYY")
            .endOf(this.date_type.toLowerCase())
            .format("YYYY-MM-DD");
        } else {
          startDate = dayjs(this.date_range[0], "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          endDate = dayjs(this.date_range[1], "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        this.calendar_range = [];
        this.calendar_range.push(dayjs(startDate).format("YYYY-MM-DD"));
        this.calendar_range.push(dayjs(endDate).add(1, "day").format("YYYY-MM-DD"));
      },
    },
    watch: {
      driver_id(){
        this.filter_data()
        this.syncDate();
        this.init_calendar();
      },
      date_range(newVal) {
        if (newVal?.length == 2) {
          this.get_schedule(this.selected_company, {
            startDate: dayjs(newVal[0]).format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(newVal[1]).format("YYYY-MM-DD HH:mm:ss"),
          });
        }
      },
      selected_company(newVal) {
        if (newVal) {
          this.get_schedule(this.selected_company, {
            startDate: dayjs(this.datetime)
              .startOf(this.date_type.toLowerCase())
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(this.datetime)
              .endOf(this.date_type.toLowerCase())
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
  
        this.syncDate();
        this.init_calendar();
      },
      datetime(newVal) {
        const type = this.date_type.toLowerCase();
        if (newVal && this["date_" + type]) {
          this.get_schedule(this.selected_company, {
            startDate: dayjs(this.datetime)
              .startOf(type)
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(this.datetime)
              .endOf(type)
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
  
        this.syncDate();
        this.init_calendar();
      },
      async car_filter_title() {
        if (this.date_type != "Custom") {
          await this.get_schedule(this.selected_company, {
            startDate: dayjs(this.datetime)
              .startOf(this.date_type.toLowerCase())
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(this.datetime)
              .endOf(this.date_type.toLowerCase())
              .format("YYYY-MM-DD HH:mm:ss"),
          });
        } else {
          await this.get_schedule(this.selected_company, {
            startDate: dayjs(this.date_range[0]).format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(this.date_range[1]).format("YYYY-MM-DD HH:mm:ss"),
          });
        }
  
        this.filter_data();
        this.syncDate();
        this.init_calendar();
      },
    },
    mounted() {
      this.$emit("request_fullscreen", true);
      this.get_driver_list();
      this.init_companies();
      this.get_car_type_list();
    },
  };
  </script>
  
  <style lang="scss">
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fc {
    a {
      color: black;
    }
  }
  .fc .fc-day-sat,
  .fc-day-sun {
    background-color: #ff800040;
  }
  .holiday {
    background-color: #ffa500; /* สีพื้นหลังสำหรับวันอังคาร */
  }
  </style>
  