import httpClient from './httpClient';

const prefix = 'car'

export default {
    async getStatusCarTypes (body) {
        const data = await httpClient({ requiresAuth: true }).post(`${prefix}/getStatusCarTypes` , body)
        return data
    },
    async getBookingDetailsByDriver(driver_id , time_start , time_end) {
        const data = await httpClient({ requiresAuth: true }).post(`${prefix}/getBookingDetailsByDriver` , {
            driver_id:driver_id,
            time_start:time_start,
            time_end:time_end
        })
        return data
    }
}