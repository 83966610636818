<template>
  <div class="dashboard-container">
    <div class="app-content">
      <div class="header sticky">
        <h1>แดชบอร์ด</h1>
        <div style="display: flex">
          <a-range-picker v-model:value="dateRange" style="margin-right: 10px" format="DD/MM/YYYY" />
          <a-button @click="loadPage(false)" style="width: fit-content" type="primary"
            class="primary-button">ค้นหา</a-button>
        </div>
      </div>

      <div class="card-container">
        <div class="dashboard-card bg-blue">
          <span class="material-symbols-outlined icon">database</span>
          <span>จำนวน Booking ทั้งหมด</span>
          <span>{{ bookingCount }}</span>
        </div>

        <div class="dashboard-card bg-blue">
          <span class="material-symbols-outlined icon">database</span>
          <span>จำนวน Booking ที่ถูกยกเลิก</span>
          <span>{{ canceledBookings }}</span>
        </div>

        <div class="dashboard-card bg-green">
          <span class="material-symbols-outlined icon">database</span>
          <span>รายได้สุทธิ</span>
          <span>{{ billingAmount }}</span>
        </div>

        <div class="dashboard-card bg-orange">
          <span class="material-symbols-outlined icon">database</span>
          <span>Biling ที่รอการเรียกเก็บ</span>
          <span>{{ billingIncompleteAmount.toLocaleString() }}</span>
        </div>
      </div>

      <a-row>
        <a-col :span="16">
          <div class="chart-container">
            <div class="chart-header bg-yellow" style="color: white">รายรับ-รายจ่าย</div>
            <div class="chart-content">
              <canvas ref="income_outcome"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="chart-container">
            <div class="chart-header bg-yellow" style="color: white">สถานะการจองจัดรถ</div>
            <div class="chart-content">
              <canvas ref="booking_status_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">ประสิทธิภาพของพนักงานขาย</div>
            <div class="chart-content-table">
              <table>
                <tr class="table-header">
                  <td>ลำดับ</td>
                  <td>ชื่อ-นามสกุล</td>
                  <td>ยอดขาย</td>
                </tr>
                <tr v-for="{ name, amount }, i in top_sales" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ name }}</td>
                  <td>{{ amount.toLocaleString() }}</td>
                </tr>
                <tr class="table-divider">
                  <td colspan="2">รวม</td>
                  <td>{{ top_sales.reduce((a, b) => a + b.amount, 0).toLocaleString() }}</td>
                </tr>
                <tr v-if="!top_sales.length" style="color: #f4f4f4">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </table>
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">พนักงานขับรถที่มีจำนวนทริปมากที่สุด</div>
            <div class="chart-content-table">
              <table>
                <tr class="table-header">
                  <td>ลำดับ</td>
                  <td>ชื่อ-นามสกุล</td>
                  <td>จำนวนงาน</td>
                  <td>จำนวนวัน</td>
                  <td>เบี้ยเลี้ยง</td>
                </tr>
                <tr v-for="{ name, amount, work_days, driver_cost }, i in top_drivers" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ name }}</td>
                  <td>{{ amount }}</td>
                  <td>{{ work_days }}</td>
                  <td>{{ driver_cost.toLocaleString() }}</td>
                </tr>
                <tr class="table-divider">
                  <td colspan="2">รวม</td>
                  <td>{{ top_drivers.reduce((a, b) => a + b.amount, 0).toLocaleString() }}</td>
                  <td>{{ top_drivers.reduce((a, b) => a + b.work_days, 0).toLocaleString() }}</td>
                  <td>{{ top_drivers.reduce((a, b) => a + b.driver_cost, 0).toLocaleString() }}</td>
                </tr>
                <tr v-if="!top_drivers.length" style="color: #f4f4f4">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </table>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">ประเภทรถที่ถูกใช้</div>
            <div class="chart-content">
              <canvas ref="most_used_cars"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">สถานะใบเสนอราคา</div>
            <div class="chart-content">
              <canvas ref="quotation_summary"></canvas>
            </div>
          </div>
        </a-col>

        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">การแจ้งซ่อม</div>
            <div class="chart-content">
              <canvas ref="repair_summary"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">สถานะใบการจอง</div>
            <div class="chart-content">
              <canvas ref="bookings_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-blue" style="color: white">ยอดขาย</div>
            <div class="chart-content">
              <canvas ref="sale_summary"></canvas>
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-blue" style="color: white">ค่าเชื้อเพลิง</div>
            <div class="chart-content">
              <canvas ref="fuel_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>

      <div class="chart-container">
        <div class="chart-header bg-blue" style="color: white">พนักงานขับรถ</div>
        <div class="chart-content-table">
          <table>
            <tr class="table-header">
              <td>ลำดับ</td>
              <td>ชื่อ</td>
              <td>นามสกุล</td>
              <td>เบอร์โทร</td>
              <td>Online Status</td>
              <td>Status</td>
              <td>Username</td>
              <td>Rating</td>
            </tr>
            <tr v-for="{ firstname, lastname, phone_no, work_status_id, work_status, username, rating }, i in drivers" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ firstname }}</td>
              <td>{{ lastname }}</td>
              <td>{{ phone_no }}</td>
              <td v-if="work_status_id == 3" class="circle green"></td>
              <td v-else class="circle red"></td>
              <td>{{ work_status?.name }}</td>
              <td>{{ username }}</td>
              <td>{{ rating }}</td>
            </tr>
            <tr class="table-divider">
              <td colspan="8"><br></td>
            </tr>
            <tr v-if="!drivers.length" style="color: #f4f4f4">
              <td colspan="8">ไม่มีข้อมูล</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import input_primary from "../../components/input_primary.vue";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import UserService from "../../api/UserService";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import inputDatePicker from "../../components/input_date_picker.vue";
import SocketService from "../../api/SocketService";
import SystemService from "../../api/SystemService";

export default {
  components: { input_primary, inputDatePicker },
  name: "Dashboard",
  props: {
    company_data: Object,
  },
  data() {
    return {
      dateRange: [dayjs().startOf("month"), dayjs().endOf("month")],
      // Dashboard
      billingIncompleteAmount: 0,
      billingAmount: 0,
      bookingCount: 0,
      canceledBookings: 0,
      billingOverall: [],
      bookingOverall: [],
      top_drivers: [],
      fuelOverall: [],
      incomeOverall: [],
      incomePerExpense: [],
      mostUsedVehicles: [],
      quotationOverall: [],
      top_sales: [],
      vehicleRepairOverall: [],

      // Drivers
      drivers: [],
      work_status: [],
      chart_keys: [
        "income_outcome",
        "most_used_cars",
        "quotation_summary",
        "booking_status_summary",
        "bookings_summary",
        "sale_summary",
        "fuel_summary",
        "repair_summary",
      ],
      chart_elements: [],
    };
  },
  methods: {
      connectSocket() {
          SocketService.connect("dashboard");
          SocketService.onBroadcast((message) => {
            this.drivers = this.isDriverLeave(message);
          });
    },
    init_charts(key) {
      // ค้นหา chart ที่มี key ตรงกันใน chart_elements
      const chartIndex = this.chart_elements.findIndex((el) => el.key === key);

      // ถ้า chart เก่ามีอยู่ ให้ทำลาย chart เก่า
      if (chartIndex !== -1) {
        this.chart_elements[chartIndex].chart.destroy();
        this.chart_elements.splice(chartIndex, 1);
      }

      const canvas = this.$refs[key];
      if (!canvas) {
        console.error(`Failed to create chart: can't find canvas with ref '${key}'`);
        return;
      }

      const context = canvas.getContext('2d');
      if (!context) {
        console.error(`Failed to create chart: can't acquire context from the canvas with ref '${key}'`);
        return;
      }

      // ดึงข้อมูล chart ใหม่จาก key
      const { type, options, data } = this[key];
      const config = {
        type: type,
        data: data,
        options: options,
      };

      // สร้าง chart ใหม่และเพิ่มเข้าไปใน chart_elements
      const newChart = new Chart(context, config);
      this.chart_elements.push({ key, chart: newChart });
    },
    isDriverLeave(item){
      item.forEach((driver) => {
        if(driver.leaves.length >= 0){
          driver.leaves.forEach(leave => {
            let current_date = dayjs().startOf("day").unix();
            // let current_date = dayjs("2024-08-6").startOf("day").unix();
            if(current_date >= dayjs(leave.time_start).startOf("day").unix() && current_date <= dayjs(leave.time_end).startOf("day").unix()){
                if(leave.approve == 1){
                  driver.work_status_id = leave.work_status_id;
                  driver.work_status = this.work_status.find((status) => status.id === driver.work_status_id);
                }
              }
            })
          }
      })
      return item
    },
    async get_data() {
      this.drivers = await UserService.GetDrivers();
      this.work_status = await SystemService.get_all('WorkStatus');
      this.drivers = this.isDriverLeave(this.drivers);
    },
    async get_dashboard() {
      const dashboard = await UserService.get_dashboard(
        this.dateRange[0].format("DD/MM/YYYY"),
        this.dateRange[1].format("DD/MM/YYYY")
      );
      this.create_charts(dashboard);
    },
    async create_charts(dashboard) {
      for (const key in dashboard) {
        if (dashboard.hasOwnProperty(key)) {
          this[key] = dashboard[key];
          if (this.chart_keys.includes(key)) {
            this.init_charts(key);
          }
        }
      }
    },
    async loadPage() {
      this.$message.loading({ content: "Loading...", key: "load", duration: 0 });
      await this.get_data();
      await this.get_dashboard();
      this.$message.destroy("load");
    },
  },
  async mounted() {
    this.connectSocket();
    await this.loadPage();
  },
  beforeDestroy() {
    this.chart_elements.forEach((el) => {
      if (el.chart) {
        el.chart.destroy(); // ทำลาย chart ทำลาย component
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.sticky {
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 1rem;
}

.table-divider {
  border-top: 1px solid #f4f4f4;
}

.chart-content-table {
  margin: 2%;
  padding: 5px;
  border: 1px solid #f4f4f4;
}

table {
  width: 100%;
  table-layout: auto;
  text-align: center;
  border-collapse: collapse;
  border-radius: 5px;

  tr {
    border-bottom: 1px solid #e8e8e8;
    line-height: 3rem;
  }
}

.chart-content {
  padding: 10px;
}

.chart-container {
  text-align: center;
  margin: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 5px;

  .chart-header {
    font-size: 16px;
    padding: 16px;
    background-color: #e5e5e5;
  }
}

.card-container {
  margin: 3%;
  display: flex;
  height: fit-content;
}

.dashboard-card {
  text-align: center;
  width: 100%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  .icon {
    font-size: 50px;
  }

  span {
    margin: 1rem;
    font-size: 14px;
    color: white;
  }
}

.dashboard-container {
  padding: 8px;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  // margin-top: 5px;
  // margin-bottom: 5px;
}

.red {
  background-color: red;
}

.green {
  background-color: rgb(0, 200, 0);
}
</style>