
import httpClient from './httpClient';
const prefix = 'company'

export default {
  async getManageUsers(page = 1, perPage = 20) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getManageUsers?page=${page}&perPage=${perPage}`)

    return data
  }
}