<template>
    <div>
        <a-divider orientation="left">{{divider}}</a-divider>
        <a-table
            :columns="table_columns"
            :data-source="target_object[target_key]"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'index'">
                    {{index+1}}
                </template>
                <template v-if="column.key === 'part_id'">
                    {{show_name_from_id(this.selectables['Part'], record.part_id)}}
                </template>
                <template v-if="column.key === 'id'">
                    <a v-if="drawer_button_text_edit" @click="go_edit(record, record.modelname, index)"><span style="font-size: 18px" class="material-symbols-outlined">edit</span></a>

                    <a @click="go_remove(record.id, record.modelname, index)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                </template>
            </template>
        </a-table>
        <a-row justify="end">
            <a-button @click="show_drawer = true" type="primary" size="large" style="margin: 4px">
                <span class="material-symbols-outlined"
                    style="font-size: 16px;margin-right: 4px">Add</span>
                {{drawer_button_text}}
            </a-button>
        </a-row>
        <a-drawer
            v-model:visible="show_drawer"
            :title="drawer_title"
            :closable="true"
            :footer="null"
        >
            <a-form
                :model="drawer_create_data" name="form" 
                autocomplete="off"
                layout="vertical"
                @finish="drawer_create">
                <a-row>
                <a-col :span="24" v-for="({ placeholder, required, input_type , options , label_key, modelname}, key_name) in drawer_fields">
                    <a-form-item :label="placeholder" :name="key_name"
                            :rules="required? [{ required, message: `โปรดระบุ ${placeholder}` }]:null">
                            <a-select v-if="input_type == 'select'" v-model:value="drawer_create_data[key_name]" show-search :placeholder="placeholder">
                                            <a-select-option v-for="option in options" :value="option.value">
                                                {{ option.label}}
                                            </a-select-option>
                            </a-select> 
                            <a-checkbox-group v-else-if="input_type == 'checkbox_result'" v-model:value="drawer_create_data[key_name]" :placeholder="placeholder">
                                            <a-checkbox value="ผ่่าน">ผ่าน</a-checkbox>
                                            <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                            </a-checkbox-group> 
                            <template v-else-if="table_columns.key === 'edit'">
                                <a @click="edit_remark(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                            </template>
                            <template v-else-if="table_columns.key === 'delete'">
                                <a @click="remove_remark(record.id,'remark',record.remark_type)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                            </template>
                            <a-select v-else-if="input_type == 'select_driver_accident_car'"
                            show-search
                            v-model:value="drawer_create_data[key_name]"
                                   :placeholder="placeholder">
                                            <a-select-option v-for="option in carOptions"
                                                :label="option.value"
                                                :value="option.value">
                                                {{ option.label }}
                                            </a-select-option>
                                        </a-select>
                            <a-input v-else
                                v-model:value="drawer_create_data[key_name]" 
                                :placeholder="placeholder"
                                :type="input_type" />
                    </a-form-item>
                </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col>
                        <a-button v-if="on_edit" html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Save</span>
                            {{drawer_button_text_edit}}
                        </a-button>
                        <a-button v-else html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Add</span>
                            {{drawer_button_text}}
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../api/SystemService'
import DriverInfo from '../api/DriverInfo';

export default {
    props: {
        divider: String,
        table_columns: Array,
        target_object: Object,
        target_key: String,
        target_modelname: String,
        drawer_title: String,
        drawer_fields: Object,
        drawer_button_text: String,
        drawer_button_text_edit: String,
    },
    name: 'table_create_drawer',
    data() {
        return {
            show_drawer: false,
            on_edit: false,
            drawer_create_data: {},
            selectables: {},
            carOptions: []
        }
    },
    methods: {
        async fetchCarData() {
            try {
                const resultCars = await SystemService.get_all('Car');
                this.carOptions = resultCars.map((car) => ({
                    label: car.car_no,
                    value: car.car_no
                }));
            } catch (error) {
                console.error("Error fetching car data:", error);
            }
        },
        async go_edit (record, type, index) {
            this.drawer_create_data = record
            this.on_edit = true
            this.show_drawer = true
        },
        async go_remove(id, type, index) {
            if (id) {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await SystemService.delete_all(type, id)
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            } else {
                this.target_object[this.target_key].splice(index, 1)
            }
        },
        async drawer_create(data){
            if (this.on_edit) {
                const { id } = this.drawer_create_data
                if (id) {
                    await SystemService.update_all(this.target_modelname, { data: data },id)
                        Swal.fire(
                            'แก้ไขข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกแก้ไขแล้ว',
                            'success'
                        )
                }
            } else {
                this.target_object[this.target_key]? this.target_object[this.target_key].push(data):
                this.target_object[this.target_key] = [data]
            }

            this.drawer_create_data = {}
            this.show_drawer = false
        },
        async get_default_fields() {
            for (var key in this.drawer_fields) {
                const { input_type, modelname } = this.drawer_fields[key]
                if (input_type == 'select') {
                    this.selectables[modelname] = await SystemService.get_all(modelname)
                }
            }
        },
        // async get_default_fields_driver() {
        //     for (var key in this.drawer_fields) {
        //         const { input_type, modelname } = this.drawer_fields[key]
        //         if (input_type == 'select') {
        //             this.selectables[modelname] = await DriverInfo.getDriverInfoByDriverNo(modelname)
        //         }
        //     }
        // },
        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
    },
    async mounted() {
        await this.get_default_fields()
        await this.get_default_fields_driver()
        this.fetchCarData();

    }
}

</script>

<style scoped>
    .ant-row {
        margin: 0
    }
</style>