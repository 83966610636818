<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card v-if="render_type == 'all'" title="จัดการลูกค้า">
                <template #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('Client')" v-if="editable" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มลูกค้า
                        </a-button>
                        <!-- <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1" v-if="editable">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown> -->
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาลูกค้า/บริษัท"
                            enter-button="ค้นหา" />
                        <a-button @click="(search_input = null)" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="filter_columns([
                        {
                            title: 'รหัสลูกค้า',
                            dataIndex: 'client_no',
                            key: 'client_no',
                            width: 150,
                            sorter: true
                        }, {
                            title: 'ชื่อบริษัท/ลูกค้า',
                            dataIndex: 'fullname',
                            key: 'fullname',
                            width: 250,
                            sorter: true,
                            ellipsis: true,
                        }, {
                            title: 'ประจำสาขาไหน',
                            dataIndex: 'client_branch_name',
                            key: 'client_branch_name',
                            width: 250,
                            sorter: true,
                            // ellipsis: true,
                        }, {
                            title: 'ชื่อ - นามสกุล ผู้ประสานงาน',
                            dataIndex: 'client_contacts',
                            key: 'client_contacts',
                            width: 200
                        }, {
                            title: 'บิลชำระล่าช้า / บิลทั้งหมด',
                            dataIndex: 'billings',
                            key: 'billings',
                            width: 200,
                            align: 'start',
                        }, {
                            title: 'อีเมลองค์กร',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150,
                            align: 'center'
                        }, {
                            title: 'อีเมลผู้ประสานงาน',
                            dataIndex: 'coordinator_email',
                            key: 'coordinator_email',
                            width: 150,
                            align: 'center'
                        },{
                            title: 'เบอร์โทร',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 125,
                            align: 'center'
                        }, {
                            title: 'เบอร์แฟกซ์',
                            dataIndex: 'fax_no',
                            key: 'fax_no',
                            width: 125,
                            align: 'center'
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 85,
                            align: 'center',
                            fixed: 'right'
                        }
                    ])" :data-source="filtered_array(clients,'fullname')" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key === 'client_no'">
                                <div>
                                    {{ record.client_no }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'client_branch_name'">
                                <div>
                                    {{ record.branches.map(branch => branch.name).join(', ') }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'coordinator_email'">
                                <div>
                                    {{ record.contacts.map(contact => contact.email)
                                                        .filter(email => email && email !== '-')
                                                        .join(', ') }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'client_contacts'">
                                <div>
                                    {{ record.contacts[0]?.firstname }} {{ record.contacts[0]?.lastname }}
                                </div>
                            </template> 
                            <template v-else-if="column.key === 'billings'">
                                <div v-if="record.billings.length">
                                    <a-badge-ribbon v-if="((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100) > 20" text="เกิน" color="red">
                                        <a-card :title="`${record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length } / ${record.billings.length} (${((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100)}%)`" size="small"></a-card>
                                    </a-badge-ribbon>
                                    <a-card v-else :title="`${record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length } / ${record.billings.length} (${((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100)}%)`" size="small"></a-card>
                                </div>
                                <div v-else>
                                    <a-card :title="`0 / 0 (0%)`" size="small"></a-card>
                                </div>
                            </template> 
                        </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'TourType'" title="จัดการประเภทกลุ่มลูกค้า">
                <template #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('TourType')" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทกลุ่มลูกค้า
                        </a-button>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาประเภทกลุ่มลูกค้า"
                            enter-button="ค้นหา" />
                        <a-button @click="(search_input = null)" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="[
                        {
                            title: 'ชื่อประเภทกลุ่มลูกค้า',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                        }, {
                            title: 'สีของประเภทกลุ่มลูกค้า',
                            dataIndex: 'color',
                            key: 'color',
                            align: 'center',
                            width: 150
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                            width: 85,
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_array(tour_types, 'name')" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-if="column.key === 'color'">
                                <a-tag :color="record.color">{{ record.color }}</a-tag>
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'Tour'" title="ประเภทกลุ่มลูกค้าท้ังหมด">
                <template #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('Tour')" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทกรุ๊ปทัวร์
                        </a-button>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-input-search v-model:value="search_input"
                            placeholder="ค้นหา ชื่อกรุ๊ปทัวร์ / ประเภทกลุ่มลูกค้า / ลูกค้า, บริษัท"
                            enter-button="ค้นหา" />
                        <a-button @click="(search_input = null)" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="[
                        {
                            title: 'ชื่อกรุ๊ปทัวร์', dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                        }, {
                            title: 'ประเภทกลุ่มลูกค้า',
                            dataIndex: 'tour_type',
                            key: 'tour_type',
                            width: 150
                        }, {
                            title: 'ลูกค้า / บริษัททัวร์',
                            dataIndex: 'client',
                            key: 'client',
                            width: 150
                        }, {
                            title: 'สถานที่เที่ยว',
                            dataIndex: 'description',
                            key: 'description',
                            width: 200
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                            width: 85,
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_array(tours, 'name')" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-if="column.key === 'client'">
                                {{ record.client?.fullname }}
                            </template>
                            <template v-else-if="column.key === 'tour_type'">
                                {{ record.tour_type?.name }}
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import ReportService from '../../api/ReportService'
import { message } from 'ant-design-vue';
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import Swal from 'sweetalert2'
import { exportXLSXFile, xlxsBase64toJson } from '@/components/helpers.js'

export default {
    name: 'manage',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_tour'),
            search_input: null,
            clients: [],
            tour_types: [],
            tours: [],
            import_fileList: [],
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        }
    },
    methods: {
        filter_columns(columns){
            return columns.filter((c)=> !this.editable ? c.key != 'id' : true)
        },
        import_change(event, field) {
            const file = event.file.originFileObj;

            // ตรวจสอบประเภทของไฟล์
            const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validFileTypes.includes(file.type)) {
                message.error({ content: 'โปรดอัปโหลดไฟล์ Excel เท่านั้น', key: 'import', duration: 1.5 });
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const result = xlxsBase64toJson(reader.result.split(',')[1]);
                
                if (result && event.file.status === 'done') {
                    console.log(result);

                    message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 });
                    try {
                        const res = await ReportService.import_client(result);
                        console.log(res);
                        if (res.status === 201) {
                            // console.log(res);
                            await this.init_cus_company_list()
                            message.success({ content: `นำเข้าข้อมูล ${result.length} รายการสำเร็จ!`, key: 'import', duration: 1.5 });
                        } else {
                            throw new Error(res.message);
                        }
                    } catch (err) {
                        if (err.response && err.response.data && err.response.data.message) {
                            message.error({ content: err.response.data.message, key: 'import', duration: 1.5 });
                        } else {
                            message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                        }
                        console.error(err);
                    }
                } else {
                    message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                }
            };
            reader.onerror = () => {
                message.error({ content: 'เกิดข้อผิดพลาดในการอ่านไฟล์', key: 'import', duration: 1.5 });
            };
        },
        export_file() {
            exportXLSXFile( { 
                id: 'รหัสต้น',
                fullname: 'ชื่อบริษัท/ลูกค้า',
                address: 'ที่อยู่',
                billing_address: 'ที่อยู่ใบเสร็จ',
                email: 'อีเมล',
                phone_no: 'เบอร์โทร',
                fax_no: 'เบอร์แฟกซ์',
                tax_id: 'เลขประจำตัวผู้เสียภาษี',
                client_type_id: `ประเภทลูกค้า ( บริษัทในประเทศ = 1, บริษัทต่างประเทศ = 2, โรงเรียน = 3, บุคคล = 4, บริษัทเอกชน = 5, หน่วยงานรัฐ = 6,อื่นๆ = 7 )`
            }, this.clients, `Clients`);
        },
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            }
        },
        filtered_array(array, key) {
            return array.filter(item => {
                if (this.search_input) {
                    return item[key].toLowerCase().includes(this.search_input.toLowerCase())
                } else {
                    return true
                }
            })
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    this.init_cus_company_list()
                    this.init_tour_tour_type()
                    this.init_tours()
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        go_edit(model_data, modelname) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/${modelname}/${model_data.id}`)
        },
        go_create(modelname) {
            this.$router.push(`/app/${modelname}/create`)
        },
        async init_cus_company_list() {
            this.clients = await SystemService.get_all('Client')
        },
        async init_tour_tour_type() {
            this.tour_types = await SystemService.get_all('TourType')
        },
        async init_tours() {
            this.tours = await SystemService.get_all('Tour')
        }
    },
    mounted() {
        this.init_cus_company_list()
        this.init_tour_tour_type()
        this.init_tours()
    }
}
</script>