<template>
    <div class="calendar-container">
      <div class="app-content">
        <div class="custom-calendar-wrapper">

        <a-calendar
            v-model:value="value"
            @select="openModal"
            @panelChange="onPanelChange"
            :locale="lang_th"
            :header-render="headerRender"
          >

            <template #dateCellRender="{ current }">
            <div v-if="selected_department != null">
                <a-badge v-if="getListData(current).length > 0">
                    <a-badge dot></a-badge
                    ><a-row>
                    <a-col>
                        <a-statistic
                        title="จำนวนงาน"
                        :value="`${getListData(current).length} งาน`"
                        />
                    </a-col> </a-row
                ></a-badge>
            </div>
            <div v-else>
                <!-- กรณียังไม่ได้เลือก department -->
            </div>
            </template>
          </a-calendar>
        </div>
      </div>
  
      <a-modal v-model:visible="visible" width="1000px">
        <template #title>
          <span>รายการจองวันที่ {{ selected_date }}</span>
        </template>

        <a-collapse v-for="item, i in (selected_department == 'cs' ? list_booking_cs : list_booking_tm)">
          <a-collapse-panel key="3" :header="selected_department == 'cs' ? getStatusCS(i) : getStatusTM(i)">
            <template #extra>
              <p
                :style="{
                  margin: '0',
                  color: item.length > 0 ? 'blue' : 'grey',
                }"
              >
                {{ item.length }} การจอง
              </p>
            </template>
            <div
              v-if="item.length > 0"
              v-for="(
                {
                  bookingId,
                  bookingNo,
                  dropOffDate,
                  dropOffPoint,
                  pickupDate,
                  pickupPoint,
                  cusName,
                  phoneNumber,
                  progress,
                  booking_car_types,
                  vehicles,
                  extras,
                  sale,
                  client_contact,
                  client,
                  client_branch,
                  raw,
                  discount,
                  status,
                },
                i
              ) in item"
              :key="i"
            >
              <a-card>
                <template #title>
                  NO:
                  <span v-if="selected_department == 'cs'">
                    <router-link
                      target="_blank"
                      :to="`/app/quotation/${bookingId}`"
                      >[{{ bookingNo }}]</router-link
                    ></span>
                    <span v-else
                    ><router-link target="_blank" :to="`/app/quotation/${bookingId}?edit=true`">[{{ bookingNo }}]</router-link></span>
                </template>
                <template #extra>
                    <div v-if="selected_department == 'cs'">
                        QT
                        <a-badge
                            :class="`badge badge-${booking_status[progress].bg}`"
                            :status="booking_status[progress].badge"
                            :text="booking_status[progress].title"
                        />
                    </div>
                    <div v-if="selected_department == 'tm'">
                        Booking
                        <a-badge
                            :class="`badge badge-${text_status[status].bg}`"
                            :status="text_status[status].badge"
                            :text="text_status[status].title"
                        />
                    </div>
                </template>
                <a-row>
                  <a-divider style="line-height: 0" orientation="left"
                    >ลูกค้าบริษัท/กรุ๊ปทัวร์/ผู้ประสานงาน/ไกด์</a-divider
                  >
                  <a-col :span="12">
                    <ul v-if="client != null">
                      <li>บริษัท : {{ cusName }}</li>
                      <li>
                        สาขา : {{ client_branch ? client_branch.name : "-" }}
                      </li>
                      <li v-if="client != null">
                        ประเภทลูกค้า :
                        {{
                          client.client_type != null
                            ? client.client_type?.name
                            : "-"
                        }}
                      </li>
                      <li v-else>ประเภทลูกค้า : -</li>
                      <li>อีเมล : {{ client.email }}</li>
                      <li>เบอร์โทรศัพท์ : {{ client.phone_no }}</li>
                      <li>เลขผู้เสียภาษี : {{ client.tax_no }}</li>
                      <li>ที่อยู่ : {{ client.address }}</li>
                    </ul>
                    <ul v-else>
                      <li>บริษัท : -</li>
                      <li>สาขา : -</li>
                      <li>ประเภทลูกค้า : -</li>
                      <li>อีเมล : -</li>
                      <li>เบอร์โทรศัพท์ : -</li>
                      <li>เลขผู้เสียภาษี : -</li>
                      <li>ที่อยู่ : -</li>
                    </ul>
                  </a-col>
                  <a-col :span="12">
                    <ul v-if="client_contact != null">
                      <li>
                        ผู้ประสานงาน :
                        {{
                          client_contact?.username
                            ? client_contact?.username
                            : "-"
                        }}
                        {{
                          client_contact?.firstname
                            ? client_contact?.firstname
                            : "-"
                        }}
                        {{
                          client_contact?.lastname
                            ? client_contact?.lastname
                            : "-"
                        }}
                      </li>
                      <li>อีเมล : {{ client_contact?.email }}</li>
                      <li>เบอร์โทรศัพท์ : {{ client_contact?.phone_no }}</li>
                      <li>
                        ชื่อประเภทกลุ่มลูกค้า : {{ client_contact?.tour?.name }}
                      </li>
                      <li>เลขที่อ้างอิง : {{ client_contact?.address }}</li>
                    </ul>
                    <ul v-else>
                      <li>ผู้ประสานงาน : -</li>
                      <li>อีเมล : -</li>
                      <li>เบอร์โทรศัพท์ : -</li>
                      <li>ชื่อประเภทกลุ่มลูกค้า : -</li>
                      <li>เลขที่อ้างอิง : {{ client_contact?.address }}</li>
                    </ul>
                  </a-col>
                </a-row>
                <a-row>
                  <a-divider style="line-height: 0" orientation="left"
                    >การจอง</a-divider
                  >
                  <a-col :span="12">
                    <ul>
                      <li>ประเภทการจอง : {{ raw.booking_type }}</li>
                      <li>จำนวนคน : {{ raw.person_qty }}</li>
                      <li>ไฟท์รับ : {{ pickupPoint }}</li>
                      <li>ไฟท์ส่ง : {{ dropOffPoint }}</li>
                    </ul>
                  </a-col>
                  <a-col :span="12">
                    <ul>
                      <li>
                        วันเวลารับส่ง : {{ render_date(raw.time_start) }} -
                        {{ render_date(raw.time_end) }}
                      </li>
                      <li>
                        จำนวนวัน :
                        {{ total_days(raw.time_start, raw.time_end) }}
                      </li>
                      <li>จำนวนวันที่ค้างคืน : {{ raw.full_day_amount }}</li>
                      <li>ทริป : {{ raw.trip?.name }}</li>
                      <li>หมายเหตุ : {{ raw.remark }}</li>
                    </ul>
                  </a-col>
                </a-row>
                <a-row>
                  <a-divider style="line-height: 0" orientation="left"
                    >รถที่เลือกใช้งาน</a-divider
                  >
                  <a-col :span="24">
                    <a-card
                      v-for="(
                        {
                          car_group,
                          car_type,
                          time_start,
                          time_end,
                          remark,
                          quantity,
                          cost,
                        },
                        i
                      ) in booking_car_types"
                      :key="i"
                      :title="`ประเภทยานพาหนะ : ${
                        car_group ? car_group.name : '-'
                      }`"
                    >
                      <a-row>
                        <a-col :span="12">
                          <ul>
                            <li>
                              รายละเอียดยานพาหนะ : {{ car_type?.name ? car_type?.name : '-'}}
                              {{ `ทะเบียน: ${vehicles[i]?.vehicleTabien ? vehicles[i]?.vehicleTabien : '-'}` }}
                            </li>
                            <li>
                              วันที่/เวลา รับลูกค้า :
                              {{ render_date(time_start) }}
                            </li>
                            <li>
                              วันที่/เวลา รับลูกค้า :
                              {{ render_date(time_end) }}
                            </li>
                            <li>
                              พนักงานขับรถ :
                              {{
                                vehicles[i]?.driverName != "undefined undefined"
                                  ? vehicles[i]?.driverName
                                  : " - "
                              }}
                            </li>
                            <li>
                              เบอร์โทรศัพท์พนักงานขับรถ :
                              {{
                                vehicles[i]?.driverPhoneNumber
                                  ? vehicles[i]?.driverPhoneNumber
                                  : " - "
                              }}
                            </li>
                          </ul>
                        </a-col>
                        <a-col :span="12">
                          <ul>
                            <li>จำนวนคัน : {{ quantity }}</li>
                            <li>ราคารวม : {{ cost }}</li>
                            <li>จุดหมาย : {{ remark }}</li>
                          </ul>
                        </a-col>
                      </a-row>
                    </a-card>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" v-if="extras.length">
                    <a-divider style="line-height: 0" orientation="left"
                      >ค่าใช้จ่ายเพิ่มเติม</a-divider
                    >
                    <ul>
                      <li
                        v-for="({ extra, price, amount }, i) in extras"
                        :key="i"
                      >
                        รายการที่ {{ i + 1 }} : {{ extra?.name }} (จำนวน
                        {{ amount }}) (ราคาต่อหน่วย {{ price }})
                      </li>
                    </ul>
                  </a-col>
                  <a-col :span="12" v-if="discount">
                    <a-divider style="line-height: 0" orientation="left"
                      >ส่วนลด</a-divider
                    >
                    <ul v-if="discount">
                      <li>{{ discount }} บาท</li>
                    </ul>
                  </a-col>
                </a-row>
                <a-row justify="end" v-if="sale">
                  <a-col :span="12">
                    <a-divider style="line-height: 0" orientation="left"
                      >คนขาย</a-divider
                    >
                    <ul>
                      <li>{{ sale?.firstname }} {{ sale?.lastname }}</li>
                    </ul>
                  </a-col>
                </a-row>
              </a-card>
  
              <a-divider></a-divider>
            </div>
            <a-empty v-else />
          </a-collapse-panel>
        </a-collapse>
        <template #footer>
          <a-button type="primary" @click="visible = false">ปิด</a-button>
        </template>
      </a-modal>
  
    </div>
  </template>
  
  <script>
  import lang_th from "ant-design-vue/es/date-picker/locale/th_TH";
  import dayjs from "dayjs";
  import buddhistEra from "dayjs/plugin/buddhistEra";
  dayjs.extend(buddhistEra);
  import CalendarService from "../../api/CalendarService";
  import moment from 'moment';
  moment.locale('th'); // ตั้งค่า locale เป็นภาษาไทย

  export default {
    name: "CustomCalendar",
    data() {
      return {
        role: null,
        value: dayjs(),
        lang_th: lang_th,
        visible: false,
        selected_date: null,
        selected_department: null,
  
        status_new: null,
        status_progress: null,
        status_done: null,
        status_cancel: null,

        list_booking_cs:[],
        list_booking_tm:[],
  
        events: [],
        booking_status: [
          { title: "ใหม่", progress: 0, badge: "green", bg: "light-yellow" },
          {
            title: "กำลังดำเนินการ",
            progress: 1,
            badge: "processing",
            bg: "success",
          },
          {
            title: "เสร็จสิ้น",
            progress: 2,
            badge: "green",
            bg: "light-green",
          },
          { title: "ยกเลิก", progress: 3, badge: "orange", bg: "error" },
        ],
        text_status: [
          { title: "แนะนำการจัดรถ", status: 0, badge: "green", bg: "success" }, //0
          { title: "รอจัดรถ", status: 1, badge: "processing", bg: "success" }, //1
          { title: "จัดรถแล้ว", status: 2, badge: "green", bg: "success" }, //2
          { title: "ถึงจุดรับแล้ว", status: 3, badge: "green", bg: "success" }, //3
          { title: "ส่งลูกค้าแล้ว", status: 4, badge: "green", bg: "success" }, //4
          {
            title: "วิ่งรถเรียบร้อยแล้ว",
            status: 5,
            badge: "success",
            bg: "success",
          }, //5
          { title: "ยกเลิกการเดินทาง", status: 6, badge: "error", bg: "error" }, //6
          {
            title: "กำลังเดินทาง",
            status: 7,
            badge: "processing",
            bg: "success",
          }, //7
          { title: "รับงาน", status: 8, badge: "green", bg: "success" }, //8
          { title: "เริ่มงาน", status: 9, badge: "green", bg: "success" }, //9
          {
            title: "รับลูกค้าขึ้นยานพาหนะ",
            status: 10,
            badge: "green",
            bg: "success",
          }, //10
          { title: "พร้อมเริ่มงาน", status: 11, badge: "green", bg: "success" }, //11
          { title: "รับของ", status: 12, badge: "green", bg: "success" }, //12
          { title: "รับของเสร็จสิ้น", status: 13, badge: "green", bg: "success" }, //13
        ],
      };
    },
    methods: {
        getStatusCS(i){
            let status = ""
            switch (i) {
                case 0: status = "ใหม่"; break;
                case 1: status = "กำลังดำเนินการ"; break;
                case 2: status = "เสร็จสิ้น"; break;
                case 3: status = "ยกเลิก"; break;
                default:   break;
            }
            return status;
        },
        getStatusTM(i){
            let status = ""
            switch (i) {
                case 0: status = "แนะนำการจัดรถ"; break;
                case 1: status = "รอจัดรถ"; break;
                case 2: status = "จัดรถแล้ว"; break;
                case 3: status = "วิ่งรถเรียบร้อยแล้ว"; break;
                default: break;
            }
            return status;
        },
      check_booking(progress, status) {
        if (progress >= 0) {
          return "";
        }
      },
      total_days(start, end) {
        return dayjs(end).diff(dayjs(start), "day") + 1;
      },
      render_date(date) {
        return dayjs(date).format("DD-MM-YYYY HH:mm");
      },
      async get_data(date) {
        this.events = await CalendarService.get_calendar(date); 
      },
      getListData(value) {
        const pool = [];
        const cs = [];
        const tm = [];
        this.events
        .filter(
            ({ date }) =>
            value.format("DD/MM/YYYY") == dayjs(date).format("DD/MM/YYYY")
        )
        .map(({ lists }) => {
            lists.map(({ status, bookingNo, progress }) => {
                if(this.selected_department == "cs"){
                    cs.push(progress)
                }else if(this.selected_department == "tm"){
                    if(status == 0 ||status == 1 || status == 2 || status == 5){
                        tm.push(status)
                    }
                }
            });
        });
        
        if(this.selected_department == "cs"){
            return cs; 
        }else if(this.selected_department == "tm"){
            return tm; 
        }
        return pool; 
        
      },
      render_status(progress) {
        return this.booking_status.find((e) => e.progress == progress);
      },
      openModal(value) {
        try {
          if(this.selected_department != null){

            const { lists } = this.events.filter(
                ({ date }) =>
                value.format("DD/MM/YYYY") == dayjs(date).format("DD/MM/YYYY")
            )[0];
            this.list_booking_cs = [];
            this.list_booking_cs.push(lists.filter(({ progress }) => progress == 0));
            this.list_booking_cs.push(lists.filter(({ progress }) => progress == 1));
            this.list_booking_cs.push(lists.filter(({ progress }) => progress == 2));
            this.list_booking_cs.push(lists.filter(({ progress }) => progress == 3));

            this.list_booking_tm = [];
            this.list_booking_tm.push(lists.filter(({ status }) => status == 0))
            this.list_booking_tm.push(lists.filter(({ status }) => status == 1))
            this.list_booking_tm.push(lists.filter(({ status }) => status == 2))
            this.list_booking_tm.push(lists.filter(({ status }) => status == 5))


            // this.list_booking_tm.push(lists.filter(({ status }) => status == 8))
           

            this.selected_date = value.format("DD/MM/YYYY");

            this.visible = true;
          }
        } catch (err) {

        }
      },
      onPanelChange(event) {
        setTimeout(() => {
          this.get_data(event);
        }, 100);
      },
      headerRender({ value, type, onChange, onTypeChange }) {
      return (
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div>
            <a-select style="width: 250px" v-model:value={this.selected_department} placeholder="เลือกแผนก">
              <a-select-option value="cs">ฝ่ายขาย</a-select-option>
              <a-select-option value="tm">ฝ่ายจัดการรถเช่า</a-select-option>
            </a-select>
          </div>
          <div style="display: flex; align-items: center;">
            <a-select
              value={value.year()}
              onChange={newYear => onChange(value.clone().year(newYear))}
              style="width: 90px; margin-right: 8px;"
            >
              {[...Array(15).keys()].map(i => (
                <a-select-option key={i} value={moment().year() - i}>
                  {moment().year() - i}
                </a-select-option>
              ))}
            </a-select>
            <a-select
              value={value.month()}
              onChange={newMonth => onChange(value.clone().month(newMonth))}
              style="width: 90px; margin-right: 8px;"
            >
              {moment.monthsShort().map((month, index) => (
                <a-select-option key={index} value={index}>
                  {month}
                </a-select-option>
              ))}
            </a-select>
            <a-radio-group
              value={type}
              onChange={e => onTypeChange(e.target.value)}
            >
              <a-radio-button value="month">เดือน</a-radio-button>
              <a-radio-button value="year">ปี</a-radio-button>
            </a-radio-group>
          </div>
        </div>
      );
      },
    },
    mounted() {
        this.get_data(this.value);
       
    },
  };
  </script>
  
  <style>
  .badge-success {
    background-color: #2db7f52e;
    color: #fff;
  }
  .badge-light-green {
    background-color: #0368242e;
    color: #fff;
  }
  .badge-light-yellow {
    background-color: #95b1072e;
    color: #fff;
  }
  .badge-warning {
    background-color: #faad14;
    color: #fff;
  }
  .badge-error {
    background-color: #f5222d;
    color: #fff;
  }
  th {
    text-align: end;
  }
  
  .ant-picker-calendar-date-content {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .ant-badge-status {
    border-radius: 3px;
    padding: 3px !important;
    margin: 0.25rem !important;
  }
  
  .calendar-container {
    padding: 15px;
  }
  
  .custom-calendar-wrapper .ant-picker-cell {
    border: 1.5px solid rgb(191, 191, 191);
  }
  </style>
  