import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getLeaveDriver(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leave`)
        return data
    }
}
